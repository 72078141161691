/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
} from '@mui/material';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { StatusCode } from '../../../../api/enumerations';
import { patchStatisticalReport } from '../../../../api/workOrders';
import {
  StaticalReportData,
  WorkOrderData,
} from '../../../../api/workOrders/types';
import { IconSaveMS } from '../../../../constants/icons';
import { GlobalContext } from '../../../../context/global';
import { getErrorMessage } from '../../../../helpers';
import useGeneral from '../../../../hooks/useGeneral';
import { useReportCompletion } from '../../../../hooks/useReportCompletion';
import Snackbar from '../../../Snackbar';
import {
  EditButton,
  StyledMenuItem,
  StyledSelect,
  StyledTextField,
} from '../styles';

interface MicroregionInputProps {
  region_predominant_use: string;
  constructive_standard: string;
  has_shallow_water_table_flooding_or_risk_zone_classification: boolean;
  infrastructure_components: string[];
  public_services: string[];
  other_public_services: string;
  observations: string;
}

interface MicroregionProps {
  propertyData: WorkOrderData | undefined;
  getDataCallback: () => Promise<void>;
}

export function Microregion({
  propertyData,
  getDataCallback,
}: MicroregionProps): JSX.Element {
  const [allFilled, setAllFilled] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [inputs, setInputs] = useState<MicroregionInputProps>({
    region_predominant_use: '',
    constructive_standard: '',
    has_shallow_water_table_flooding_or_risk_zone_classification: Boolean(''),
    infrastructure_components: [],
    public_services: [],
    other_public_services: '',
    observations: '',
  });
  const infrastructureComponents = [
    { label: 'pavimentação', value: 'pavimentação' },
    { label: 'gás canalizado', value: 'gás canalizado' },
    { label: 'energia elétrica', value: 'energia elétrica' },
    {
      label: 'drenagem das águas pluviais',
      value: 'drenagem das águas pluviais',
    },
    { label: 'rede de água potável', value: 'rede de água potável' },
    { label: 'rede de telefonia', value: 'rede de telefonia' },
    { label: 'iluminação pública', value: 'iluminação pública' },
    { label: 'esgoto sanitário', value: 'esgoto sanitário' },
  ];

  const publicServices = [
    { label: 'rede bancária', value: 'rede bancária' },
    { label: 'coleta de lixo', value: 'coleta de lixo' },
    { label: 'centro comercial', value: 'centro comercial' },
    { label: 'lazer', value: 'lazer' },
    { label: 'escolas', value: 'escolas' },
    { label: 'shopping center', value: 'shopping center' },
    { label: 'clínicas e hospitais', value: 'clínicas e hospitais' },
    { label: 'parque', value: 'parque' },
    { label: 'metrô', value: 'metrô' },
    { label: 'aeroporto', value: 'aeroporto' },
    { label: 'segurança pública', value: 'segurança pública' },
    {
      label: 'transporte público - outros',
      value: 'transporte público - outros',
    },
  ];

  const { osId } = useGeneral();
  const { toggleCompletion } = useReportCompletion();
  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const handleInputChange = (
    field: keyof MicroregionInputProps,
    value: string | number | boolean
  ): void => {
    setInputs((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSelectInfrastructureComponents = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const { value, checked } = event.target;

    setInputs((prev) => ({
      ...prev,
      infrastructure_components: checked
        ? [...prev.infrastructure_components, value]
        : [...prev.infrastructure_components.filter((item) => item !== value)],
    }));
  };

  const handleSelectPublicServices = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const { value, checked } = event.target;

    setInputs((prev) => ({
      ...prev,
      public_services: checked
        ? [...prev.public_services, value]
        : [...prev.public_services.filter((item) => item !== value)],
    }));
  };

  const checkIfAllFieldsAreFilled = (
    object: MicroregionInputProps
  ): boolean => {
    return Object.entries(object).every(
      ([key, value]) =>
        key === 'infrastructure_components' ||
        key === 'public_services' ||
        value !== ''
    );
  };

  const handleSendJSON = useCallback(async () => {
    try {
      const data: StaticalReportData = {
        appraisal_microregion_information: inputs,
      };
      const response = await patchStatisticalReport(osId, data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (!data) {
        throw new Error('Algo deu errado, tente novamente');
      }
      if (response.detail.status_code === StatusCode.OK) {
        toggleCompletion('microregion', true);
        getDataCallback();
      }
    } catch (error) {
      setErrorMessage(true);
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
    }
  }, [
    inputs,
    osId,
    setErrorMessage,
    setOpenSnackbar,
    setSnackbarMessage,
    toggleCompletion,
  ]);

  useEffect(() => {
    if (
      propertyData?.manual_statistical_report?.appraisal_microregion_information
    ) {
      setInputs(
        propertyData?.manual_statistical_report
          .appraisal_microregion_information
      );
      toggleCompletion('microregion', true);
    }
  }, [propertyData, toggleCompletion]);

  useEffect(() => {
    setAllFilled(checkIfAllFieldsAreFilled(inputs));
    if (allFilled) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [allFilled, inputs, toggleCompletion]);

  return (
    <Grid container columnGap={2} rowGap={2}>
      <Grid item xs={3.5}>
        <StyledSelect
          select
          label="região - uso predominante"
          value={inputs.region_predominant_use}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) =>
            handleInputChange('region_predominant_use', e.target.value)
          }
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="residencial unifamiliar">
            residencial unifamiliar
          </StyledMenuItem>
          <StyledMenuItem value="residencial multifamiliar">
            residencial multifamiliar
          </StyledMenuItem>
          <StyledMenuItem value="comercial">comercial</StyledMenuItem>
          <StyledMenuItem value="industrial">industrial</StyledMenuItem>
          <StyledMenuItem value="institucional">institucional</StyledMenuItem>
          <StyledMenuItem value="outro">outro</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={4}>
        <StyledSelect
          select
          label="padrão construtivo predominante na região"
          value={inputs.constructive_standard}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) =>
            handleInputChange('constructive_standard', e.target.value)
          }
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="alto (superior, luxo)">
            alto (superior, luxo)
          </StyledMenuItem>
          <StyledMenuItem value="alto (por predominância)">
            alto (por predominância)
          </StyledMenuItem>
          <StyledMenuItem value="normal (com aspecto de alto)">
            normal (com aspecto de alto)
          </StyledMenuItem>
          <StyledMenuItem value="normal (forte predominância)">
            normal (forte predominância)
          </StyledMenuItem>
          <StyledMenuItem value="normal (com aspecto de baixo)">
            normal (com aspecto de baixo)
          </StyledMenuItem>
          <StyledMenuItem value="baixo">baixo</StyledMenuItem>
          <StyledMenuItem value="mínimo">mínimo</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3.5}>
        <StyledSelect
          select
          label="acessos"
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="bom / boa">bom / boa</StyledMenuItem>
          <StyledMenuItem value="muito bom">muito bom</StyledMenuItem>
          <StyledMenuItem value="ótimo">ótimo</StyledMenuItem>
          <StyledMenuItem value="regular">regular</StyledMenuItem>
          <StyledMenuItem value="ruim">ruim</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={12}>
        <StyledSelect
          select
          label="o imóvel está localizado em área com lençol freático raso, região de alagamentos ou classificada como de risco pela defesa civil"
          value={String(
            inputs.has_shallow_water_table_flooding_or_risk_zone_classification
          )}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) =>
            handleInputChange(
              'has_shallow_water_table_flooding_or_risk_zone_classification',
              e.target.value
            )
          }
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="true">sim</StyledMenuItem>
          <StyledMenuItem value="false">não</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={12} margin={2}>
        <FormLabel>infraestrutura urbana</FormLabel>
      </Grid>
      <Grid container rowSpacing={4}>
        {infrastructureComponents.map((item) => (
          <Grid item xs={3}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value={item.value}
                    checked={inputs.infrastructure_components.includes(
                      item.value
                    )}
                    onChange={handleSelectInfrastructureComponents}
                  />
                }
                label={item.label}
              />
            </FormGroup>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} margin={2}>
        <FormLabel>serviços públicos e comunitários</FormLabel>
      </Grid>
      <Grid container rowSpacing={4}>
        {publicServices.map((item) => (
          <Grid item xs={3}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value={item.value}
                    checked={inputs.public_services.includes(item.value)}
                    onChange={handleSelectPublicServices}
                  />
                }
                label={item.label}
              />
            </FormGroup>
          </Grid>
        ))}
        <Grid item xs={3}>
          <StyledTextField
            label="outros"
            value={inputs.other_public_services}
            onChange={(e) =>
              handleInputChange('other_public_services', e.target.value)
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12} marginTop={2}>
        <StyledTextField
          label="aspectos importantes observados"
          value={inputs.observations}
          onChange={(e) => handleInputChange('observations', e.target.value)}
        />
      </Grid>
      <Grid container justifyContent="flex-end" columnGap={2}>
        <Grid item paddingRight={2}>
          <EditButton disabled={isDisabled} onClick={() => handleSendJSON()}>
            {IconSaveMS}
          </EditButton>
        </Grid>
      </Grid>
      {openSnackbar && <Snackbar />}
    </Grid>
  );
}
