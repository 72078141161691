/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
import { Add } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { StatusCode } from '../../../../api/enumerations';
import { patchStatisticalReport } from '../../../../api/workOrders';
import {
  StaticalReportData,
  WorkOrderData,
} from '../../../../api/workOrders/types';
import { IconDeleteMS, IconSaveMS } from '../../../../constants/icons';
import { GlobalContext } from '../../../../context/global';
import { getErrorMessage } from '../../../../helpers';
import useGeneral from '../../../../hooks/useGeneral';
import { useReportCompletion } from '../../../../hooks/useReportCompletion';
import Snackbar from '../../../Snackbar';
import {
  EditButton,
  OutlinedButton,
  StyledMenuItem,
  StyledSelect,
  StyledTextField,
} from '../styles';

interface InputData {
  name: string;
  description: string;
  significance_level: number;
  type: string;
  growth: string;
  appraisal_attribute: string;
  sample_extent: string;
}

interface VariablesProps {
  propertyData: WorkOrderData | undefined;
  getDataCallback: () => Promise<void>;
}

export function Variables({
  propertyData,
  getDataCallback,
}: VariablesProps): JSX.Element {
  const [allFilled, setAllFilled] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const [inputs, setInputs] = useState<InputData[]>([
    {
      name: '',
      description: '',
      significance_level: 0,
      type: '',
      growth: '',
      appraisal_attribute: '',
      sample_extent: '',
    },
  ]);

  const { osId } = useGeneral();
  const { toggleCompletion } = useReportCompletion();
  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const addInput = (): void => {
    setInputs([
      ...inputs,
      {
        name: '',
        description: '',
        significance_level: 0,
        type: '',
        growth: '',
        appraisal_attribute: '',
        sample_extent: '',
      },
    ]);
  };

  const removeInput = (index: number): void => {
    setInputs(inputs.filter((_, i) => i !== index));
  };

  const handleInputChange = (
    index: number,
    field: keyof InputData,
    value: string | number
  ): void => {
    const updatedInputs = [...inputs];
    updatedInputs[index] = { ...updatedInputs[index], [field]: value };
    setInputs(updatedInputs);
  };

  const handleSendJSON = useCallback(async () => {
    try {
      const data: StaticalReportData = {
        variables: inputs,
      };
      const response = await patchStatisticalReport(osId, data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (!data) {
        throw new Error('Algo deu errado, tente novamente');
      }
      if (response.detail.status_code === StatusCode.OK) {
        toggleCompletion('variables', true);
        getDataCallback();
      }
    } catch (error) {
      setErrorMessage(true);
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
    }
  }, [
    inputs,
    osId,
    setErrorMessage,
    setOpenSnackbar,
    setSnackbarMessage,
    toggleCompletion,
  ]);

  useEffect(() => {
    if (propertyData?.manual_statistical_report?.variables) {
      setInputs(propertyData?.manual_statistical_report.variables);
    }
  }, [propertyData]);

  useEffect(() => {
    const areAllInputsFilled = inputs.every(
      (input) =>
        input.name.trim() !== '' &&
        input.description.trim() !== '' &&
        input.significance_level.valueOf() !== 0 &&
        input.type.trim() !== '' &&
        input.growth.trim() !== '' &&
        input.appraisal_attribute.trim() !== '' &&
        input.sample_extent.trim() !== ''
    );
    setAllFilled(areAllInputsFilled);
    setIsDisabled(false);

    if (allFilled === true) {
      setTimeout(() => {
        toggleCompletion('variables', true);
      }, 1500);

      setTimeout(() => {
        console.log(inputs);
      }, 5000);
    } else {
      toggleCompletion('variables', false);
      setIsDisabled(true);
    }
  }, [inputs, allFilled, toggleCompletion]);

  return (
    <Grid container rowGap={2} columnGap={2}>
      {inputs.map((input, index) => (
        <Grid container rowGap={2} columnGap={2} key={index}>
          <Grid item xs={3}>
            <StyledTextField
              label="nome da variável"
              value={input.name}
              color="secondary"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(index, 'name', e.target.value)
              }
            />
          </Grid>
          <Grid item xs={3}>
            <StyledTextField
              label="descrição da variável"
              value={input.description}
              color="secondary"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(index, 'description', e.target.value)
              }
            />
          </Grid>
          <Grid item xs={3}>
            <StyledTextField
              label="significância da variável"
              value={input.significance_level}
              color="secondary"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(
                  index,
                  'significance_level',
                  Number(e.target.value)
                )
              }
              type="number"
            />
          </Grid>
          <Grid item xs={3}>
            <StyledSelect
              select
              label="tipo da variável"
              SelectProps={{
                IconComponent: ExpandMoreIcon,
              }}
              value={input.type}
              color="secondary"
              onChange={(e) => handleInputChange(index, 'type', e.target.value)}
            >
              <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
              <StyledMenuItem value="dependente">dependente</StyledMenuItem>
              <StyledMenuItem value="independente">independente</StyledMenuItem>
            </StyledSelect>
          </Grid>
          <Grid item xs={3}>
            <StyledSelect
              select
              label="crescimento da variável"
              SelectProps={{
                IconComponent: ExpandMoreIcon,
              }}
              color="secondary"
              value={input.growth}
              onChange={(e) =>
                handleInputChange(index, 'growth', e.target.value)
              }
            >
              <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
              <StyledMenuItem value="positiva">positiva</StyledMenuItem>
              <StyledMenuItem value="negativa">negativa</StyledMenuItem>
            </StyledSelect>
          </Grid>
          <Grid item xs={3}>
            <StyledTextField
              label="atributo do avaliando para a variável"
              value={input.appraisal_attribute}
              color="secondary"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(index, 'appraisal_attribute', e.target.value)
              }
            />
          </Grid>
          <Grid item xs={3}>
            <StyledTextField
              label="amplitude da amostra"
              value={input.sample_extent}
              color="secondary"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(index, 'sample_extent', e.target.value)
              }
            />
          </Grid>
          <Grid item xs={2}>
            <OutlinedButton
              onClick={() => removeInput(index)}
              disabled={inputs.length === 1}
            >
              {IconDeleteMS}
            </OutlinedButton>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={2}>
        <OutlinedButton onClick={addInput}>
          <Add />
        </OutlinedButton>
      </Grid>
      <Grid container justifyContent="flex-end" columnGap={2}>
        <Grid item paddingRight={2}>
          <EditButton disabled={isDisabled} onClick={() => handleSendJSON()}>
            {IconSaveMS}
          </EditButton>
        </Grid>
      </Grid>
      {openSnackbar && <Snackbar />}
    </Grid>
  );
}
