/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-len */
import { Add } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Tooltip } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { StatusCode, YesOrNo } from '../../../../api/enumerations';
import { patchStatisticalReport } from '../../../../api/workOrders';
import {
  ImprovementsProps,
  StaticalReportData,
  WorkOrderData,
} from '../../../../api/workOrders/types';
import { IconDeleteMS, IconSaveMS } from '../../../../constants/icons';
import { selectYesOrNo } from '../../../../constants/selectOptions';
import { GlobalContext } from '../../../../context/global';
import { getErrorMessage } from '../../../../helpers';
import useGeneral from '../../../../hooks/useGeneral';
import { useReportCompletion } from '../../../../hooks/useReportCompletion';
import { SelectTextField } from '../../../CustomInput';
import Snackbar from '../../../Snackbar';
import {
  EditButton,
  OutlinedButton,
  StyledMenuItem,
  StyledSelect,
  StyledTextField,
} from '../styles';
import { InitialStateImprovements } from './utils';

interface ConstructionInformationProps {
  appraisal_occupied_during_inspection: boolean | null;
  sewage_solution: string;
  framing: string;
  total_constructed_or_private_area: number;
  water_supply: string;
  number_of_parking_spaces: number;
  registered_area: number;
  not_registered_area: number;
  observations: string;
}

interface ImprovementsContruction {
  propertyData: WorkOrderData | undefined;
  getDataCallback: () => Promise<void>;
}

export function ImprovementsContruction({
  propertyData,
  getDataCallback,
}: ImprovementsContruction): JSX.Element {
  const [isDisabled, setIsDisabled] = useState(true);
  const [doesNotApply, setDoesNotApply] = useState(YesOrNo.NO);
  const [appraisalPreservation, setAppraisalPreservation] = useState('');
  const [improventsIsDisabled, setImproventsIsDisabled] = useState(true);
  const [improventsInputs, setImprovementsInputs] = useState<
    ImprovementsProps[]
  >(InitialStateImprovements);
  const [constructionInfoInputs, setConstructionInfoInputs] =
    useState<ConstructionInformationProps>({
      appraisal_occupied_during_inspection: false,
      sewage_solution: '',
      framing: '',
      total_constructed_or_private_area: 0,
      water_supply: '',
      number_of_parking_spaces: 0,
      registered_area: 0,
      not_registered_area: 0,
      observations: '',
    });

  const { osId } = useGeneral();
  const { toggleCompletion } = useReportCompletion();
  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const handlImprovementsInputChange = (
    index: number,
    field: keyof ImprovementsProps,
    value: string | number
  ): void => {
    const updatedInputs = [...improventsInputs];
    updatedInputs[index] = { ...updatedInputs[index], [field]: value };
    setImprovementsInputs(updatedInputs);
  };

  const addImprovementsInputs = (): void => {
    setImprovementsInputs([
      ...improventsInputs,
      {
        name: '',
        area: 0,
        finishing_standard: '',
        age: 0,
        preservation_state: '',
        observations: '',
      },
    ]);
  };

  const removeImprovementsInputs = (index: number): void => {
    setImprovementsInputs(improventsInputs.filter((_, i) => i !== index));
  };

  const handleConstructionInfoInputChange = (
    field: keyof ConstructionInformationProps,
    value: string | number | boolean
  ): void => {
    setConstructionInfoInputs((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSendJSON = useCallback(async () => {
    const improvementsInformationData =
      doesNotApply === YesOrNo.YES
        ? 'não se aplica'
        : {
            appraisal_preservation_state: appraisalPreservation,
            improvements:
              improventsInputs !== InitialStateImprovements
                ? improventsInputs
                : [],
          };
    try {
      const data: StaticalReportData = {
        improvements_information: improvementsInformationData,
        construction_information: constructionInfoInputs,
      };
      const response = await patchStatisticalReport(osId, data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (!data) {
        throw new Error('Algo deu errado, tente novamente');
      }
      if (response.detail.status_code === StatusCode.OK) {
        toggleCompletion('improvements', true);
        getDataCallback();
      }
    } catch (error) {
      setErrorMessage(true);
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
    }
  }, [
    appraisalPreservation,
    constructionInfoInputs,
    doesNotApply,
    improventsInputs,
    osId,
    setErrorMessage,
    setOpenSnackbar,
    setSnackbarMessage,
    toggleCompletion,
  ]);

  const checkIfAllFieldsAreFilled = (
    object: ConstructionInformationProps
  ): boolean => {
    return Object.values(object).every((value) => value !== '');
  };

  function isConstructionInformationProps(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    obj: any
  ): obj is ConstructionInformationProps {
    return true;
  }

  useEffect(() => {
    if (
      propertyData?.manual_statistical_report?.improvements_information?.toString() ===
        'não se aplica' &&
      isConstructionInformationProps(
        propertyData?.manual_statistical_report?.construction_information
      ) &&
      propertyData?.manual_statistical_report?.construction_information !==
        undefined
    ) {
      setDoesNotApply(YesOrNo.YES);
      setConstructionInfoInputs(
        propertyData?.manual_statistical_report?.construction_information
      );
      toggleCompletion('improvements', true);
    }
    if (
      Array.isArray(
        propertyData?.manual_statistical_report?.improvements_information
      ) &&
      propertyData?.manual_statistical_report?.improvements_information !==
        undefined &&
      propertyData?.manual_statistical_report?.construction_information !==
        undefined
    ) {
      setImprovementsInputs(
        propertyData?.manual_statistical_report?.improvements_information
      );
      setConstructionInfoInputs(
        propertyData?.manual_statistical_report?.construction_information
      );
      toggleCompletion('improvements', true);
    }
  }, [propertyData, toggleCompletion]);

  useEffect(() => {
    const areAllImproventsInputsFilled = improventsInputs.every(
      (input) =>
        input.name.trim() !== '' &&
        input.area.valueOf() !== 0 &&
        input.finishing_standard.trim() !== '' &&
        input.age.valueOf() !== 0 &&
        input.preservation_state.trim() !== '' &&
        input.observations.trim() !== ''
    );

    const areAllConstructionInfoFilled = checkIfAllFieldsAreFilled(
      constructionInfoInputs
    );

    if (doesNotApply === YesOrNo.NO) {
      if (
        appraisalPreservation !== '' &&
        areAllImproventsInputsFilled &&
        areAllConstructionInfoFilled
      ) {
        setIsDisabled(false);
      }
    } else if (doesNotApply === YesOrNo.YES && areAllConstructionInfoFilled) {
      setIsDisabled(false);
    }
  }, [
    appraisalPreservation,
    constructionInfoInputs,
    doesNotApply,
    improventsInputs,
  ]);

  useEffect(() => {
    if (doesNotApply === YesOrNo.NO) {
      setImproventsIsDisabled(false);
    } else {
      setImproventsIsDisabled(true);
      setAppraisalPreservation('');
      setImprovementsInputs([
        {
          name: '',
          area: 0,
          finishing_standard: '',
          age: 0,
          preservation_state: '',
          observations: '',
        },
      ]);
    }
  }, [doesNotApply]);

  return (
    <Grid container rowGap={2} columnGap={2}>
      <Grid item container columnGap={2}>
        <Grid item xs={4}>
          <SelectTextField
            id=""
            label="não se aplica"
            value={doesNotApply}
            setValue={setDoesNotApply}
            selectOptions={selectYesOrNo()}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledSelect
            select
            disabled={improventsIsDisabled}
            label="estado de conservação imóvel avaliando"
            value={appraisalPreservation}
            SelectProps={{
              IconComponent: ExpandMoreIcon,
            }}
            onChange={(e) => setAppraisalPreservation(e.target.value)}
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="bom">bom</StyledMenuItem>
            <StyledMenuItem value="em construção">em construção</StyledMenuItem>
            <StyledMenuItem value="regular">regular</StyledMenuItem>
            <StyledMenuItem value="ruim">ruim</StyledMenuItem>
          </StyledSelect>
        </Grid>
      </Grid>
      {improventsInputs.map((item, index) => (
        <Grid item container rowGap={2} columnGap={2}>
          <Grid item xs={3}>
            <StyledSelect
              select
              disabled={improventsIsDisabled}
              label="benfeitoria / divisão intera"
              value={item.name}
              SelectProps={{
                IconComponent: ExpandMoreIcon,
              }}
              onChange={(e) =>
                handlImprovementsInputChange(index, 'name', e.target.value)
              }
            >
              <StyledMenuItem disabled value="">
                selecione uma opção
              </StyledMenuItem>
              <StyledMenuItem value="alvenaria">alvenaria</StyledMenuItem>
              <StyledMenuItem value="dry-wall">dry wall</StyledMenuItem>
              <StyledMenuItem value="madeira">madeira</StyledMenuItem>
              <StyledMenuItem value="misto">misto</StyledMenuItem>
            </StyledSelect>
          </Grid>
          <Grid item xs={3}>
            <StyledTextField
              disabled={improventsIsDisabled}
              label="área"
              type="number"
              value={item.area}
              onChange={(e) =>
                handlImprovementsInputChange(
                  index,
                  'area',
                  Number(e.target.value)
                )
              }
            />
          </Grid>
          <Grid item xs={3}>
            <StyledSelect
              select
              disabled={improventsIsDisabled}
              label="padrão de acabamento"
              value={item.finishing_standard}
              SelectProps={{
                IconComponent: ExpandMoreIcon,
              }}
              onChange={(e) =>
                handlImprovementsInputChange(
                  index,
                  'finishing_standard',
                  e.target.value
                )
              }
            >
              <StyledMenuItem disabled value="">
                selecione uma opção
              </StyledMenuItem>
              <StyledMenuItem value="alto">alto</StyledMenuItem>
              <StyledMenuItem value="baixo">baixo</StyledMenuItem>
              <StyledMenuItem value="mínimo">mínimo</StyledMenuItem>
              <StyledMenuItem value="normal/ médio">
                normal / médio
              </StyledMenuItem>
            </StyledSelect>
          </Grid>
          <Grid item xs={3}>
            <StyledTextField
              disabled={improventsIsDisabled}
              label="idade aparente"
              value={item.age}
              onChange={(e) =>
                handlImprovementsInputChange(
                  index,
                  'age',
                  Number(e.target.value)
                )
              }
            />
          </Grid>
          <Grid item xs={3}>
            <StyledTextField
              disabled={improventsIsDisabled}
              label="estado de conservação"
              value={item.preservation_state}
              onChange={(e) =>
                handlImprovementsInputChange(
                  index,
                  'preservation_state',
                  e.target.value
                )
              }
            />
          </Grid>
          <Grid container columnGap={2} alignItems="center">
            <Grid item sx={{ flexGrow: 1 }}>
              <StyledTextField
                disabled={improventsIsDisabled}
                label="observações"
                value={item.observations}
                onChange={(e) =>
                  handlImprovementsInputChange(
                    index,
                    'observations',
                    e.target.value
                  )
                }
              />
            </Grid>
            <Grid item>
              <EditButton
                disabled={improventsInputs.length === 1}
                onClick={() => removeImprovementsInputs(index)}
              >
                {IconDeleteMS}
              </EditButton>
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <OutlinedButton
          disabled={improventsIsDisabled}
          onClick={addImprovementsInputs}
        >
          <Add />
        </OutlinedButton>
      </Grid>
      <Grid item container rowGap={2} columnGap={2}>
        <Tooltip
          arrow
          placement="top"
          title="imóvel ocupado no momento da vistoria"
        >
          <Grid item xs={3}>
            <StyledSelect
              select
              label="imóvel ocupado no momento da vistoria"
              value={
                constructionInfoInputs.appraisal_occupied_during_inspection
              }
              SelectProps={{
                IconComponent: ExpandMoreIcon,
              }}
              onChange={(e) =>
                handleConstructionInfoInputChange(
                  'appraisal_occupied_during_inspection',
                  e.target.value
                )
              }
            >
              <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
              <StyledMenuItem value="true">sim</StyledMenuItem>
              <StyledMenuItem value="false">não</StyledMenuItem>
            </StyledSelect>
          </Grid>
        </Tooltip>
        <Grid item xs={3}>
          <StyledSelect
            select
            label="solução esgoto sanitário"
            value={constructionInfoInputs.sewage_solution}
            SelectProps={{
              IconComponent: ExpandMoreIcon,
            }}
            onChange={(e) =>
              handleConstructionInfoInputChange(
                'sewage_solution',
                e.target.value
              )
            }
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="rede de esgoto">
              rede de esgoto
            </StyledMenuItem>
            <StyledMenuItem value="fossa séptica">fossa séptica</StyledMenuItem>
            <StyledMenuItem value="sumidouro">sumidouro</StyledMenuItem>
            <StyledMenuItem value="não possui">não possui</StyledMenuItem>
          </StyledSelect>
        </Grid>
        <Grid item xs={3}>
          <StyledSelect
            select
            label="esquadrias"
            value={constructionInfoInputs.framing}
            SelectProps={{
              IconComponent: ExpandMoreIcon,
            }}
            onChange={(e) =>
              handleConstructionInfoInputChange('framing', e.target.value)
            }
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="alumínio">alumínio</StyledMenuItem>
            <StyledMenuItem value="ferro">ferro</StyledMenuItem>
            <StyledMenuItem value="madeira">madeira</StyledMenuItem>
            <StyledMenuItem value="PVC">PVC</StyledMenuItem>
            <StyledMenuItem value="vidro temperado">
              vidro temperado
            </StyledMenuItem>
            <StyledMenuItem value="outros">outros</StyledMenuItem>
          </StyledSelect>
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <StyledTextField
            type="number"
            label="área construída/ privativa total (m²)"
            value={constructionInfoInputs.total_constructed_or_private_area}
            onChange={(e) =>
              handleConstructionInfoInputChange(
                'total_constructed_or_private_area',
                Number(e.target.value)
              )
            }
          />
        </Grid>
      </Grid>
      <Grid item container rowGap={2} columnGap={2}>
        <Grid item xs={3}>
          <StyledSelect
            select
            label="solução de abastecimento de água"
            value={constructionInfoInputs.water_supply}
            SelectProps={{
              IconComponent: ExpandMoreIcon,
            }}
            onChange={(e) =>
              handleConstructionInfoInputChange('water_supply', e.target.value)
            }
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="rede de água potável">
              rede de água potável
            </StyledMenuItem>
            <StyledMenuItem value="poço">poço</StyledMenuItem>
            <StyledMenuItem value="não possui">não possui</StyledMenuItem>
          </StyledSelect>
        </Grid>
        <Grid item xs={3}>
          <StyledTextField
            type="number"
            label="total de vagas"
            value={constructionInfoInputs.number_of_parking_spaces}
            onChange={(e) =>
              handleConstructionInfoInputChange(
                'number_of_parking_spaces',
                Number(e.target.value)
              )
            }
          />
        </Grid>
        <Grid item xs={3}>
          <StyledTextField
            type="number"
            label="área averbada (m²)"
            value={constructionInfoInputs.registered_area}
            onChange={(e) =>
              handleConstructionInfoInputChange(
                'registered_area',
                Number(e.target.value)
              )
            }
          />
        </Grid>
        <Grid item xs={3}>
          <StyledTextField
            type="number"
            label="área não averbada (m²)"
            value={constructionInfoInputs.not_registered_area}
            onChange={(e) =>
              handleConstructionInfoInputChange(
                'not_registered_area',
                Number(e.target.value)
              )
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          label="Demais Informações Relevantes (Descrição da divisão interna / benfeitoria, não descritos acima):"
          value={constructionInfoInputs.observations}
          onChange={(e) =>
            handleConstructionInfoInputChange('observations', e.target.value)
          }
        />
      </Grid>
      <Grid container justifyContent="flex-end" columnGap={2}>
        <Grid item paddingRight={2}>
          <EditButton disabled={isDisabled} onClick={() => handleSendJSON()}>
            {IconSaveMS}
          </EditButton>
        </Grid>
      </Grid>
      {openSnackbar && <Snackbar />}
    </Grid>
  );
}
