/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { StatusCode } from '../../../../api/enumerations';
import { patchStatisticalReport } from '../../../../api/workOrders';
import {
  StaticalReportData,
  WorkOrderData,
} from '../../../../api/workOrders/types';
import { IconSaveMS } from '../../../../constants/icons';
import { GlobalContext } from '../../../../context/global';
import { getErrorMessage } from '../../../../helpers';
import useGeneral from '../../../../hooks/useGeneral';
import { useReportCompletion } from '../../../../hooks/useReportCompletion';
import Snackbar from '../../../Snackbar';
import {
  EditButton,
  StyledMenuItem,
  StyledSelect,
  StyledTextField,
} from '../styles';

interface WarrantyStatementsProps {
  can_be_accepted: boolean | undefined;
  unaccepted_reason: string;
}

interface WarrantyProps {
  propertyData: WorkOrderData | undefined;
  getDataCallback: () => Promise<void>;
}

export function WarrantyStatements({
  propertyData,
  getDataCallback,
}: WarrantyProps): JSX.Element {
  const [isDisabled, setIsDisabled] = useState(true);
  const [inputs, setInputs] = useState<WarrantyStatementsProps>({
    can_be_accepted: undefined,
    unaccepted_reason: '',
  });

  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { osId } = useGeneral();
  const { toggleCompletion } = useReportCompletion();

  useEffect(() => {
    if (propertyData?.manual_statistical_report?.warranty_statements) {
      setInputs(propertyData?.manual_statistical_report.warranty_statements);
      setIsDisabled(
        propertyData?.manual_statistical_report?.warranty_statements
          ?.can_be_accepted || false
      );
      toggleCompletion('warrantyStatements', true);
    } else {
      toggleCompletion('warrantyStatements', false);
    }
  }, [propertyData, toggleCompletion]);

  const handleInputChange = (
    field: keyof WarrantyStatementsProps,
    value: string | number | boolean
  ): void => {
    setInputs((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSendJSON = useCallback(async () => {
    try {
      const data: StaticalReportData = {
        warranty_statements: inputs,
      };
      const response = await patchStatisticalReport(osId, data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (!data) {
        throw new Error('Algo deu errado, tente novamente');
      }
      if (response.detail.status_code === StatusCode.OK) {
        getDataCallback();
      }
    } catch (error) {
      setErrorMessage(true);
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
    }
  }, [
    inputs,
    osId,
    setErrorMessage,
    setOpenSnackbar,
    setSnackbarMessage,
    toggleCompletion,
  ]);

  useEffect(() => {
    if (inputs.can_be_accepted === undefined) {
      setIsDisabled(true);
    } else if (String(inputs.can_be_accepted) === 'true') {
      setIsDisabled(false);
    } else if (
      String(inputs.can_be_accepted) === 'false' &&
      inputs.unaccepted_reason.length > 0
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [inputs]);

  return (
    <Grid container rowGap={2}>
      <Grid item xs={12}>
        <StyledSelect
          select
          label=""
          value={String(inputs.can_be_accepted)}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => {
            handleInputChange('can_be_accepted', e.target.value);
            if (e.target.value === 'true') {
              handleInputChange('unaccepted_reason', '');
            }
          }}
        >
          <StyledMenuItem value={undefined}>selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="true">sim</StyledMenuItem>
          <StyledMenuItem value="false">não</StyledMenuItem>
        </StyledSelect>
      </Grid>
      {String(inputs.can_be_accepted) === 'false' && (
        <Grid item xs={12}>
          <StyledTextField
            label=""
            required
            placeholder="justifique*"
            value={inputs.unaccepted_reason}
            onChange={(e) =>
              handleInputChange('unaccepted_reason', e.target.value)
            }
          />
        </Grid>
      )}
      <Grid container justifyContent="flex-end" columnGap={2}>
        <Grid item paddingRight={2}>
          <EditButton disabled={isDisabled} onClick={() => handleSendJSON()}>
            {IconSaveMS}
          </EditButton>
        </Grid>
      </Grid>
      {openSnackbar && <Snackbar />}
    </Grid>
  );
}
