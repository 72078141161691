/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Typography,
} from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { listCompanyLicenses } from '../../../api/hive/licensedCompanies';
import { LicensedCompanyData } from '../../../api/hive/licensedCompanies/types';
import {
  CustomTextField,
  SelectTextField,
} from '../../../components/CustomInput';
import { OutlinedButton } from '../../../components/UI/Button';
import { selectRegistrationUf } from '../../../constants/selectOptions';
import { GlobalContext } from '../../../context/global';
import useErrorMessage from '../../../hooks/useErrorMessage';
import { Absence } from './Absence';
import { coverageData } from './mockData';
import { GridItem, SectionGrid, SectionTitle, StyledSelect } from './styles';
import { TableInfo } from './Table';
import { presentCoverageTableHead } from './Table/presenter';

export default function Accreditation(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [licensedCompanies, setLicensedCompanies] = useState<
    LicensedCompanyData[]
  >([]);
  const [selectedCompany, setSelectedCompany] = useState<
    LicensedCompanyData | undefined
  >();
  const [uf, setUf] = useState(0);
  const [city, setCity] = useState('');
  const [microregion, setMicroregion] = useState('');

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();

  const getDataCallback = useCallback(async () => {
    setSelectedCompany(undefined);
    setLoading(true);
    try {
      const response = await listCompanyLicenses();

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setLicensedCompanies(response.data);
      setSelectedCompany(response.data[0]);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getDataCallback();
  }, []);

  return (
    <Box>
      {loading && <LinearProgress sx={{ m: '20px' }} />}
      {!loading && licensedCompanies.length > 1 && (
        <FormControl sx={{ width: '380px' }}>
          <InputLabel id="company">empresa</InputLabel>
          <StyledSelect
            labelId="company"
            id="selected-company"
            label="empresa"
            value={selectedCompany?.id}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              const selected = licensedCompanies.filter(
                (el) => el.id === e.target.value
              );
              setSelectedCompany(selected[0]);
            }}
            displayEmpty
          >
            {licensedCompanies.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.hive_company}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>
      )}
      {selectedCompany && (
        <>
          <SectionGrid container>
            <Grid item xs={6}>
              <SectionTitle>Credenciamento</SectionTitle>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="end">
              <Box display="flex" gap="8px">
                <Typography fontSize="18px">Status atual: </Typography>
                <Typography fontWeight={500} fontSize="18px">
                  {selectedCompany.is_active ? 'ativo' : 'inativo'}
                </Typography>
              </Box>
            </Grid>
            <GridItem item xs={12} alignSelf="end">
              <OutlinedButton>pedir descredenciamento</OutlinedButton>
            </GridItem>
          </SectionGrid>
          <SectionGrid container>
            <Grid item xs={12}>
              <SectionTitle>Local de cobertura</SectionTitle>
            </Grid>
            <GridItem marginTop="28px" item xs={12}>
              <Box display="flex" gap="36px">
                <SelectTextField
                  required
                  id="uf"
                  label="UF"
                  value={uf}
                  setValue={setUf}
                  selectOptions={selectRegistrationUf()}
                />
                <CustomTextField
                  required
                  id="city"
                  label="polo"
                  value={city}
                  setValue={setCity}
                />
                <CustomTextField
                  required
                  id="microregion"
                  label="microrregião"
                  value={microregion}
                  setValue={setMicroregion}
                />
              </Box>
            </GridItem>
            <GridItem item xs={12}>
              <TableInfo
                tableData={coverageData}
                presentTableHead={presentCoverageTableHead}
                accordion
              />
            </GridItem>
          </SectionGrid>
          <Absence companyId={selectedCompany.id} />
        </>
      )}
    </Box>
  );
}
