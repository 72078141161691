export interface PresentTableHead {
  id: number;
  label: string | React.ReactElement;
  width: string;
}

export interface PresentTableValues {
  id: number;
  value: string | number | React.ReactElement;
}

export const presentCoverageTableHead = (): PresentTableHead[] => {
  return [
    {
      id: 1,
      label: 'Edital',
      width: '30%',
    },
    {
      id: 2,
      label: 'UF',
      width: '20%',
    },
    {
      id: 3,
      label: '',
      width: '',
    },
  ];
};
