/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { StatusCode } from '../../../../api/enumerations';
import { patchStatisticalReport } from '../../../../api/workOrders';
import {
  StaticalReportData,
  WorkOrderData,
} from '../../../../api/workOrders/types';
import { IconSaveMS } from '../../../../constants/icons';
import { GlobalContext } from '../../../../context/global';
import { getErrorMessage } from '../../../../helpers';
import useGeneral from '../../../../hooks/useGeneral';
import { useReportCompletion } from '../../../../hooks/useReportCompletion';
import Snackbar from '../../../Snackbar';
import {
  EditButton,
  StyledMenuItem,
  StyledSelect,
  StyledTextField,
} from '../styles';

interface EvaluationSummaryInputProps {
  value: number;
  basic_unitary_cost_calculus_reasoning: string;
  reasoning_level: string;
  bdi_reasoning: string;
  bdi_reasoning_observations: string;
  bdi_percentage: number;
  depreciated_improvements_value: number;
  commercialization_factor: string;
  evolutionary_method_evaluation_result: string;
  observations: string;
}

interface EvaluationSummaryProps {
  propertyData: WorkOrderData | undefined;
  getDataCallback: () => Promise<void>;
}

export function EvaluationSummary({
  propertyData,
  getDataCallback,
}: EvaluationSummaryProps): JSX.Element {
  const [allFilled, setAllFilled] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const [inputs, setInputs] = useState<EvaluationSummaryInputProps>({
    value: 0,
    basic_unitary_cost_calculus_reasoning: '',
    reasoning_level: '',
    bdi_reasoning: '',
    bdi_reasoning_observations: '',
    bdi_percentage: 0,
    depreciated_improvements_value: 0,
    commercialization_factor: '',
    evolutionary_method_evaluation_result: '',
    observations: '',
  });

  const { osId } = useGeneral();
  const { toggleCompletion } = useReportCompletion();
  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const handleInputChange = (
    field: keyof EvaluationSummaryInputProps,
    value: string | number | boolean
  ): void => {
    setInputs((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const checkIfAllFieldsAreFilled = (
    object: EvaluationSummaryInputProps
  ): boolean => {
    return Object.values(object).every((value) => value !== '');
  };

  const handleSendJSON = useCallback(async () => {
    try {
      const data: StaticalReportData = {
        evaluation_summary: inputs,
      };
      const response = await patchStatisticalReport(osId, data);
      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (!data) {
        throw new Error('Algo deu errado, tente novamente');
      }
      if (response.detail.status_code === StatusCode.OK) {
        toggleCompletion('evaluationSummary', true);
        getDataCallback();
      }
    } catch (error) {
      setErrorMessage(true);
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
    }
  }, [
    inputs,
    osId,
    setErrorMessage,
    setOpenSnackbar,
    setSnackbarMessage,
    toggleCompletion,
  ]);

  useEffect(() => {
    if (propertyData?.manual_statistical_report?.evaluation_summary) {
      setInputs(propertyData?.manual_statistical_report.evaluation_summary);
      if (allFilled === true) toggleCompletion('evaluationSummary', true);
    }
  }, [allFilled, propertyData, toggleCompletion]);

  useEffect(() => {
    setAllFilled(checkIfAllFieldsAreFilled(inputs));

    if (allFilled === true) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [allFilled, inputs, toggleCompletion]);

  return (
    <Grid container rowGap={2} columnGap={2} sx={{}}>
      <Grid item xs={2.5}>
        <StyledTextField
          label="valor atribuído"
          value={inputs.value}
          type="number"
          onChange={(e) => handleInputChange('value', e.target.value)}
        />
      </Grid>
      <Grid item xs={4}>
        <StyledTextField
          label="o custo unitário básico da construção foi atribuída como?"
          value={inputs.basic_unitary_cost_calculus_reasoning}
          onChange={(e) =>
            handleInputChange(
              'basic_unitary_cost_calculus_reasoning',
              e.target.value
            )
          }
        />
      </Grid>
      <Grid item xs={2.5}>
        <StyledSelect
          select
          label="grau de fundamentação"
          value={String(inputs.reasoning_level)}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('reasoning_level', e.target.value)}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="I">I</StyledMenuItem>
          <StyledMenuItem value="II">II</StyledMenuItem>
          <StyledMenuItem value="III">III</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={2.5}>
        <StyledSelect
          select
          label="o BDI utilizado foi?"
          value={String(inputs.bdi_reasoning)}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('bdi_reasoning', e.target.value)}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="calculado">calculado</StyledMenuItem>
          <StyledMenuItem value="justificado">justificado</StyledMenuItem>
          <StyledMenuItem value="arbitrado">arbitrado</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          label="observações sobre o BDI utilizado"
          value={inputs.bdi_reasoning_observations}
          onChange={(e) =>
            handleInputChange('bdi_reasoning_observations', e.target.value)
          }
        />
      </Grid>
      <Grid item xs={2.5}>
        <StyledTextField
          label="percentual de BDI utilizado"
          value={inputs.bdi_percentage}
          type="number"
          onChange={(e) => handleInputChange('bdi_percentage', e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          label="valor total das benfeitorias depreciadas"
          value={inputs.depreciated_improvements_value}
          type="number"
          onChange={(e) =>
            handleInputChange('depreciated_improvements_value', e.target.value)
          }
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          label="fator de comercialização"
          value={inputs.commercialization_factor}
          onChange={(e) =>
            handleInputChange('commercialization_factor', e.target.value)
          }
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          label="Resultado da avaliação – Método Evolutivo"
          value={inputs.evolutionary_method_evaluation_result}
          onChange={(e) =>
            handleInputChange(
              'evolutionary_method_evaluation_result',
              e.target.value
            )
          }
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          label="incluir observações relevantes"
          value={inputs.observations}
          onChange={(e) => handleInputChange('observations', e.target.value)}
        />
      </Grid>
      <Grid container justifyContent="flex-end" columnGap={2}>
        <Grid item paddingRight={2}>
          <EditButton disabled={isDisabled} onClick={() => handleSendJSON()}>
            {IconSaveMS}
          </EditButton>
        </Grid>
      </Grid>
      {openSnackbar && <Snackbar />}
    </Grid>
  );
}
