/* eslint-disable react/require-default-props */
import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';

import { convertRegistrationUf } from '../../../../api/enumerations';
import {
  IconExpandLessMS,
  IconExpandMoreMS,
} from '../../../../constants/icons';
import { ICoverageTableData } from '../mockData';
import { PresentTableHead } from './presenter';
import {
  AccordionBox,
  Cell,
  CoverageBox,
  StyledButton,
  StyledTableCell,
  StyledTableContainer,
} from './styles';

export function Row({ data }: { data: ICoverageTableData }): JSX.Element {
  const [open, setOpen] = useState(false);
  const [openCoverage, setOpenCoverage] = useState(false);

  return (
    <>
      <TableRow>
        <StyledTableCell
          sx={{ borderBottom: 'none' }}
          onClick={() => setOpen(!open)}
        >
          <AccordionBox>
            {data.notice_name} {open ? IconExpandMoreMS : IconExpandLessMS}
          </AccordionBox>
        </StyledTableCell>
        <StyledTableCell align="center" sx={{ borderBottom: 'none' }}>
          {convertRegistrationUf(data.uf)}
        </StyledTableCell>
        <StyledTableCell align="center" sx={{ borderBottom: 'none' }}>
          <StyledButton>pedir descredenciamento</StyledButton>
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderTop: 'none' }}
          colSpan={12}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <CoverageBox>
              <AccordionBox
                sx={{ fontSize: '18px', fontWeight: 600 }}
                onClick={() => setOpenCoverage(!openCoverage)}
              >
                {data.coverage.city}
                {openCoverage ? IconExpandMoreMS : IconExpandLessMS}
              </AccordionBox>
              <Collapse in={openCoverage} timeout="auto" unmountOnExit>
                <Box display="flex" flexDirection="column" paddingLeft="7rem">
                  {data.coverage.microregions.map((region) => (
                    <Typography sx={{ fontSize: '18px' }} key={region.id}>
                      {region.city}
                    </Typography>
                  ))}
                </Box>
              </Collapse>
            </CoverageBox>
          </Collapse>
        </StyledTableCell>
      </TableRow>
    </>
  );
}

interface TableProps {
  tableData: ICoverageTableData[];
  presentTableHead: () => PresentTableHead[];
  accordion?: boolean;
}

export function TableInfo({
  tableData,
  presentTableHead,
  accordion,
}: TableProps): JSX.Element {
  return (
    <StyledTableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {presentTableHead().map((cell) => (
              <Cell align="center" key={cell.id} width={cell.width}>
                {cell.label}
              </Cell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody id="table">
          {accordion &&
            tableData.map((element) => (
              <Row data={element as ICoverageTableData} key={element.id} />
            ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}
