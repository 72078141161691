/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { StatusCode, YesOrNo } from '../../../../api/enumerations';
import { patchStatisticalReport } from '../../../../api/workOrders';
import {
  LandInformationProps,
  StaticalReportData,
  WorkOrderData,
} from '../../../../api/workOrders/types';
import { IconSaveMS } from '../../../../constants/icons';
import { selectYesOrNo } from '../../../../constants/selectOptions';
import { GlobalContext } from '../../../../context/global';
import { getErrorMessage } from '../../../../helpers';
import useGeneral from '../../../../hooks/useGeneral';
import { useReportCompletion } from '../../../../hooks/useReportCompletion';
import { SelectTextField } from '../../../CustomInput';
import Snackbar from '../../../Snackbar';
import {
  EditButton,
  StyledMenuItem,
  StyledSelect,
  StyledTextField,
} from '../styles';

interface TerrainProps {
  propertyData: WorkOrderData | undefined;
  getDataCallback: () => Promise<void>;
}

export function Terrain({
  propertyData,
  getDataCallback,
}: TerrainProps): JSX.Element {
  const [doesNotApply, setDoesNotApply] = useState(YesOrNo.NO);
  const [allFilled, setAllFilled] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [terrainIsDisabled, setTerrainIsDisabled] = useState(true);
  const [inputs, setInputs] = useState<LandInformationProps>({
    topography: '',
    implantation_type: '',
    shape: '',
    land_position: '',
    surface_draining: '',
    greide_quota: '',
    number_of_fronts: 0,
    total_area_in_squared_meters: 0,
    ideal_fraction: 0,
    testada_front: '',
    zoning: '',
    allowed_uses_by_zoning: '',
    maximum_allowed_occupation_rate_percentage: 0,
    utilization_coefficient_percentage: 0,
    maximum_height_allowed: 0,
    front: '',
    side: '',
    back: '',
    land_fencing_type: '',
    restriction_fraction_level: '',
    observations: '',
  });

  const percentage = Array.from({ length: 10 }, (_, index) => (index + 1) * 10);

  const { osId } = useGeneral();
  const { toggleCompletion } = useReportCompletion();
  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const handleInputChange = (
    field: keyof LandInformationProps,
    value: string | number | boolean
  ): void => {
    setInputs((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const checkIfAllFieldsAreFilled = (object: LandInformationProps): boolean => {
    return Object.values(object).every((value) => value !== '');
  };

  const handleSendJSON = useCallback(async () => {
    const landInformationData =
      doesNotApply === YesOrNo.YES ? 'não se aplica' : inputs;
    try {
      const data: StaticalReportData = {
        land_information: landInformationData,
      };
      const response = await patchStatisticalReport(osId, data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (!data) {
        throw new Error('Algo deu errado, tente novamente');
      }
      if (response.detail.status_code === StatusCode.OK) {
        toggleCompletion('terrain', true);
        getDataCallback();
      }
    } catch (error) {
      setErrorMessage(true);
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
    }
  }, [
    doesNotApply,
    inputs,
    osId,
    setErrorMessage,
    setOpenSnackbar,
    setSnackbarMessage,
    toggleCompletion,
  ]);

  function isLandInformationProps(obj: any): obj is LandInformationProps {
    return true;
  }

  useEffect(() => {
    if (
      propertyData?.manual_statistical_report?.land_information?.toString() ===
      'não se aplica'
    ) {
      setDoesNotApply(YesOrNo.YES);
      toggleCompletion('terrain', true);
    } else if (
      isLandInformationProps(
        propertyData?.manual_statistical_report?.land_information
      ) &&
      propertyData?.manual_statistical_report?.land_information !== undefined
    ) {
      setInputs(propertyData?.manual_statistical_report?.land_information);
      toggleCompletion('terrain', true);
    }
  }, [propertyData, toggleCompletion]);

  useEffect(() => {
    setAllFilled(checkIfAllFieldsAreFilled(inputs));
    if (doesNotApply === YesOrNo.YES) {
      setIsDisabled(false);
    } else if (doesNotApply === YesOrNo.NO && allFilled) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [allFilled, doesNotApply, inputs]);

  useEffect(() => {
    if (doesNotApply !== YesOrNo.NO) {
      setTerrainIsDisabled(true);
      setInputs({
        topography: '',
        implantation_type: '',
        shape: '',
        land_position: '',
        surface_draining: '',
        greide_quota: '',
        number_of_fronts: 0,
        total_area_in_squared_meters: 0,
        ideal_fraction: 0,
        testada_front: '',
        zoning: '',
        allowed_uses_by_zoning: '',
        maximum_allowed_occupation_rate_percentage: 0,
        utilization_coefficient_percentage: 0,
        maximum_height_allowed: 0,
        front: '',
        side: '',
        back: '',
        land_fencing_type: '',
        restriction_fraction_level: '',
        observations: '',
      });
    } else {
      setTerrainIsDisabled(false);
    }
  }, [doesNotApply]);

  return (
    <Grid container columnGap={2} rowGap={2}>
      <Grid item xs={2.5}>
        <SelectTextField
          id=""
          label="não se aplica"
          selectOptions={selectYesOrNo()}
          value={doesNotApply}
          setValue={setDoesNotApply}
        />
      </Grid>
      <Grid item xs={2.5}>
        <StyledSelect
          select
          disabled={terrainIsDisabled}
          label="topografia"
          value={inputs.topography}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('topography', e.target.value)}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="aclive maior que 10%">
            aclive maior que 10%
          </StyledMenuItem>
          <StyledMenuItem value="declive maior que 10%">
            declive maior que 10%
          </StyledMenuItem>
          <StyledMenuItem value="plano">plano</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          disabled={terrainIsDisabled}
          label="tipo de implantação"
          value={inputs.implantation_type}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) =>
            handleInputChange('implantation_type', e.target.value)
          }
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="isolado">isolado</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          disabled={terrainIsDisabled}
          label="formato"
          value={inputs.shape}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('shape', e.target.value)}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="irregular">irregular</StyledMenuItem>
          <StyledMenuItem value="regular">regular</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          disabled={terrainIsDisabled}
          label="posição do terreno"
          value={inputs.land_position}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('land_position', e.target.value)}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="meio de quadra">meio de quadra</StyledMenuItem>
          <StyledMenuItem value="esquina">esquina</StyledMenuItem>
          <StyledMenuItem value="encravado">encravado</StyledMenuItem>
          <StyledMenuItem value="quadra inteira">quadra inteira</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={2.5}>
        <StyledSelect
          select
          disabled={terrainIsDisabled}
          label="drenagem (superfície)"
          value={inputs.surface_draining}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) =>
            handleInputChange('surface_draining', e.target.value)
          }
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="alagável">alagável</StyledMenuItem>
          <StyledMenuItem value="brejoso">brejoso</StyledMenuItem>
          <StyledMenuItem value="seco">seco</StyledMenuItem>
          <StyledMenuItem value="outra">outra</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={2.5}>
        <StyledSelect
          select
          disabled={terrainIsDisabled}
          label="cota do greide"
          value={inputs.greide_quota}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('greide_quota', e.target.value)}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="abaixo">abaixo</StyledMenuItem>
          <StyledMenuItem value="acima">acima</StyledMenuItem>
          <StyledMenuItem value="mesmo nível">mesmo nível</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          disabled={terrainIsDisabled}
          label="nº de frentes"
          value={String(inputs.number_of_fronts)}
          onChange={(e) =>
            handleInputChange('number_of_fronts', Number(e.target.value))
          }
        />
      </Grid>
      <Grid item xs={2.5}>
        <StyledTextField
          type="number"
          disabled={terrainIsDisabled}
          label="área total (m²)"
          value={String(inputs.total_area_in_squared_meters)}
          onChange={(e) =>
            handleInputChange(
              'total_area_in_squared_meters',
              Number(e.target.value)
            )
          }
        />
      </Grid>
      <Grid item xs={2.5}>
        <StyledTextField
          type="number"
          disabled={terrainIsDisabled}
          label="fração ideal (%)"
          value={String(inputs.ideal_fraction)}
          onChange={(e) =>
            handleInputChange('ideal_fraction', Number(e.target.value))
          }
          variant="outlined"
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          disabled={terrainIsDisabled}
          label="testada (frente)"
          value={inputs.testada_front}
          onChange={(e) => handleInputChange('testada_front', e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          disabled={terrainIsDisabled}
          label="qual zoneamento"
          value={inputs.zoning}
          onChange={(e) => handleInputChange('zoning', e.target.value)}
        />
      </Grid>
      <Grid item xs={5}>
        <StyledTextField
          disabled={terrainIsDisabled}
          label="quais usos permitidos pelo zoneamento"
          value={inputs.allowed_uses_by_zoning}
          onChange={(e) =>
            handleInputChange('allowed_uses_by_zoning', e.target.value)
          }
        />
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          disabled={terrainIsDisabled}
          label="taxa de ocupação máxima permitida"
          value={inputs.maximum_allowed_occupation_rate_percentage}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) =>
            handleInputChange(
              'maximum_allowed_occupation_rate_percentage',
              e.target.value
            )
          }
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          {percentage.map((item) => (
            <StyledMenuItem value={item}>{item}%</StyledMenuItem>
          ))}
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          disabled={terrainIsDisabled}
          placeholder="%"
          label="coeficiente de aproveitamento"
          value={String(inputs.utilization_coefficient_percentage)}
          onChange={(e) =>
            handleInputChange(
              'utilization_coefficient_percentage',
              Number(e.target.value)
            )
          }
        />
      </Grid>
      <Grid item xs={2.5}>
        <StyledTextField
          type="number"
          disabled={terrainIsDisabled}
          label="altura máxima permitida para edificação"
          value={String(inputs.maximum_height_allowed)}
          onChange={(e) =>
            handleInputChange('maximum_height_allowed', Number(e.target.value))
          }
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          disabled={terrainIsDisabled}
          label="frontal"
          value={inputs.front}
          onChange={(e) => handleInputChange('front', e.target.value)}
        />
      </Grid>
      <Grid item xs={2.5}>
        <StyledTextField
          disabled={terrainIsDisabled}
          label="lateral"
          value={inputs.side}
          onChange={(e) => handleInputChange('side', e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          disabled={terrainIsDisabled}
          label="fundos"
          value={inputs.back}
          onChange={(e) => handleInputChange('back', e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          disabled={terrainIsDisabled}
          label="tipo de cercamento do terreno"
          value={inputs.land_fencing_type}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) =>
            handleInputChange('land_fencing_type', e.target.value)
          }
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="sem cercamento">sem cercamento</StyledMenuItem>
          <StyledMenuItem value="arame">arame</StyledMenuItem>
          <StyledMenuItem value="tela">tela</StyledMenuItem>
          <StyledMenuItem value="gradil">gradil</StyledMenuItem>
          <StyledMenuItem value="muro">muro</StyledMenuItem>
          <StyledMenuItem value="outro">outro</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          disabled={terrainIsDisabled}
          label="nível de restrição (fração)"
          value={inputs.restriction_fraction_level}
          onChange={(e) =>
            handleInputChange('restriction_fraction_level', e.target.value)
          }
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          disabled={terrainIsDisabled}
          label="observações"
          value={inputs.observations}
          onChange={(e) => handleInputChange('observations', e.target.value)}
        />
      </Grid>
      <Grid container justifyContent="flex-end" columnGap={2}>
        <Grid item paddingRight={2}>
          <EditButton
            type="submit"
            disabled={isDisabled}
            onClick={() => handleSendJSON()}
          >
            {IconSaveMS}
          </EditButton>
        </Grid>
      </Grid>
      {openSnackbar && <Snackbar />}
    </Grid>
  );
}
