/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { StatusCode } from '../../../../api/enumerations';
import { patchStatisticalReport } from '../../../../api/workOrders';
import {
  StaticalReportData,
  WorkOrderData,
} from '../../../../api/workOrders/types';
import { IconSaveMS } from '../../../../constants/icons';
import { GlobalContext } from '../../../../context/global';
import { getErrorMessage } from '../../../../helpers';
import useGeneral from '../../../../hooks/useGeneral';
import { useReportCompletion } from '../../../../hooks/useReportCompletion';
import { CustomTextField } from '../../../CustomInput';
import Snackbar from '../../../Snackbar';
import { EditButton } from '../styles';

interface AdditionalInformationProps {
  propertyData: WorkOrderData | undefined;
  getDataCallback: () => Promise<void>;
}

export function AdditionalInformation({
  propertyData,
  getDataCallback,
}: AdditionalInformationProps): JSX.Element {
  const [additionalInformation, setAdditionalInformation] = useState('');

  const { osId } = useGeneral();
  const { toggleCompletion } = useReportCompletion();
  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const handleSendJSON = useCallback(async () => {
    const data: StaticalReportData = {
      independent_unities_complementary_information: additionalInformation,
    };
    const { detail } = await patchStatisticalReport(osId, data);
    if (detail.status_code === 0) {
      toggleCompletion('additionalInfo', true);
    }

    try {
      const data: StaticalReportData = {
        independent_unities_complementary_information: additionalInformation,
      };
      const response = await patchStatisticalReport(osId, data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (!data) {
        throw new Error('Algo deu errado, tente novamente');
      }
      if (response.detail.status_code === StatusCode.OK) {
        getDataCallback();
      }
    } catch (error) {
      setErrorMessage(true);
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
    }
  }, [
    additionalInformation,
    osId,
    setErrorMessage,
    setOpenSnackbar,
    setSnackbarMessage,
    toggleCompletion,
  ]);

  useEffect(() => {
    if (propertyData) {
      if (
        propertyData?.manual_statistical_report
          ?.independent_unities_complementary_information
      ) {
        setAdditionalInformation(
          propertyData?.manual_statistical_report
            ?.independent_unities_complementary_information || ''
        );
      }
      if (
        propertyData.manual_statistical_report
          ?.independent_unities_complementary_information &&
        propertyData.manual_statistical_report
          ?.independent_unities_complementary_information.length > 0
      ) {
        toggleCompletion('additionalInfo', true);
      } else {
        toggleCompletion('additionalInfo', false);
      }
    }
  }, [propertyData]);

  return (
    <Grid container rowGap={2}>
      <Grid item xs={12}>
        <CustomTextField
          id=""
          label=""
          value={additionalInformation}
          setValue={setAdditionalInformation}
        />
      </Grid>
      <Grid container justifyContent="flex-end" columnGap={2}>
        <Grid item paddingRight={2}>
          <EditButton
            disabled={additionalInformation === ''}
            onClick={() => handleSendJSON()}
          >
            {IconSaveMS}
          </EditButton>
        </Grid>
      </Grid>
      {openSnackbar && <Snackbar />}
    </Grid>
  );
}
