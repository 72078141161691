import { handleUrl, myHeadersWithToken } from '../../global';
import {
  HiveRequestsProps,
  ListCompanyLicensesResponse,
  NoticesAnnouncementsProps,
} from './types';

export const listCompanyLicenses = (): Promise<ListCompanyLicensesResponse> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/companies/me/licensed-companies`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getNoticeAnnouncements = (
  page: number,
  size: number
): Promise<NoticesAnnouncementsProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/companies/me/notice-announcements?page=${page}&size=${size}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getHiveRequests = (
  page: number,
  size: number
): Promise<HiveRequestsProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/companies/me/hive-requests?page=${page}&size=${size}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};
