import { Box } from '@mui/material';
import { useState } from 'react';

import { CustomTextField } from '../../../components/CustomInput';
import {
  AttachFileButon,
  AttachmentsBox,
  CancelButton,
  CloseButton,
  DialogTitle,
  FileList,
  InputBox,
  List,
  StyledDialog,
  StyledIcon,
  SubmitBox,
  SubmitButton,
  TopBox,
  UploadBox,
} from '../../../components/Dialog/AttachFilesDialog/styles';
import { RoundedButton } from '../../../components/UI/Button';
import { WhiteCircularProgress } from '../../../components/UI/Typography';
import {
  IconAttachFileMS,
  IconCloseMS,
  IconDownloadMS,
  IconEditMS,
  IconUploadMS,
} from '../../../constants/icons';
import { Constants } from '../../../constants/revision';
import useGeneral from '../../../hooks/useGeneral';

interface UploadFilesProps {
  fileType: 'pdf' | 'memorial';
}

export function UploadFiles({ fileType }: UploadFilesProps): JSX.Element {
  const [edit, setEdit] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState('');

  const dialog = useGeneral();

  const reset = (): void => {
    dialog.handleClose();
    setEdit(false);
    setLoading(false);
  };

  const handleTitle = (): string => {
    if (edit) {
      if (fileType === 'pdf') {
        return Constants.editPdf;
      }
      return Constants.editMemorial;
    }
    if (fileType === 'pdf') {
      return Constants.addPdf;
    }
    return Constants.addMemorial;
  };

  return (
    <>
      <RoundedButton onClick={dialog.handleOpen}>
        {edit ? IconEditMS : IconUploadMS}
        {fileType === 'pdf' ? Constants.addPdf : Constants.addMemorial}
      </RoundedButton>
      <StyledDialog open={dialog.open} onClose={reset}>
        <>
          <TopBox>
            <DialogTitle>
              {edit ? IconEditMS : IconUploadMS}
              {handleTitle()}
            </DialogTitle>
            <CloseButton onClick={reset}>{IconCloseMS}</CloseButton>
          </TopBox>
          {(!edit || showInput) && (
            <UploadBox>
              <InputBox marginTop="0px !important">
                <CustomTextField
                  disabled
                  id="file-name"
                  label="arquivo"
                  value={fileName}
                  setValue={setFileName}
                />
                <label htmlFor="uploadAttachFiles">
                  <input
                    accept="image/*,application/pdf"
                    id="uploadAttachFiles"
                    type="file"
                    style={{ display: 'none' }}
                  />
                  <AttachFileButon component="span">
                    selecionar arquivo
                  </AttachFileButon>
                </label>
              </InputBox>
              <SubmitBox>
                <CancelButton onClick={dialog.handleClose}>
                  cancelar
                </CancelButton>
                <SubmitButton>
                  {loading ? <WhiteCircularProgress size={22} /> : 'adicionar'}
                </SubmitButton>
              </SubmitBox>
            </UploadBox>
          )}
          <AttachmentsBox marginTop="21px">
            <FileList>
              {fileType === 'pdf' ? (
                <List>
                  <Box>
                    {IconAttachFileMS}
                    Relatório PDF
                  </Box>
                  <Box>
                    <StyledIcon
                      disabled={!edit}
                      onClick={() => setShowInput(!showInput)}
                    >
                      {IconEditMS}
                    </StyledIcon>
                    <StyledIcon disabled>{IconDownloadMS}</StyledIcon>
                  </Box>
                </List>
              ) : (
                <List>
                  <Box>
                    {IconAttachFileMS}
                    Memorial de cálculo
                  </Box>
                  <Box>
                    <StyledIcon
                      disabled={!edit}
                      onClick={() => setShowInput(!showInput)}
                    >
                      {IconEditMS}
                    </StyledIcon>
                    <StyledIcon disabled>{IconDownloadMS}</StyledIcon>
                  </Box>
                </List>
              )}
            </FileList>
          </AttachmentsBox>
        </>
      </StyledDialog>
    </>
  );
}
