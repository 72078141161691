/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-bitwise */
/* eslint-disable react/require-default-props */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Tooltip,
} from '@mui/material';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  PropertyType,
  StatusCode,
  YesOrNo,
} from '../../../../api/enumerations';
import { patchStatisticalReport } from '../../../../api/workOrders';
import {
  CondominiumInformationProps,
  StaticalReportData,
  WorkOrderData,
} from '../../../../api/workOrders/types';
import { IconSaveMS } from '../../../../constants/icons';
import { selectYesOrNo } from '../../../../constants/selectOptions';
import { GlobalContext } from '../../../../context/global';
import { getErrorMessage } from '../../../../helpers';
import useGeneral from '../../../../hooks/useGeneral';
import { useReportCompletion } from '../../../../hooks/useReportCompletion';
import { SelectTextField } from '../../../CustomInput';
import Snackbar from '../../../Snackbar';
import {
  EditButton,
  StyledMenuItem,
  StyledSelect,
  StyledTextField,
} from '../styles';

interface CondominiumProps {
  propertyData: WorkOrderData | undefined;
  getDataCallback: () => Promise<void>;
}

export function Condominium({
  propertyData,
  getDataCallback,
}: CondominiumProps): JSX.Element {
  const [isDisabled, setIsDisabled] = useState(true);
  const [condominiumIsDisabled, setCondominiumIsDisabled] = useState(true);
  const [doesNotApply, setDoesNotApply] = useState(YesOrNo.NO);
  const [inputs, setInputs] = useState<CondominiumInformationProps>({
    type:
      propertyData?.real_estate_kind === PropertyType.APARTMENT
        ? 'apartamento'
        : 'casa',
    framing: '',
    wall_covering: '',
    number_of_floors: 0,
    number_of_units_per_floor: 0,
    appraisal_position: '',
    age: 0,
    number_of_blocks: 0,
    number_of_elevators: 0,
    use_type: '',
    number_of_units: 0,
    finishing_standard: '',
    preservation_state: '',
    location_status_level: '',
    area: 0,
    main_facade_components: [],
    fire_protection: [],
    other_fire_protections: '',
    infrastructure_services_and_equipments_components: [],
  });

  const { osId } = useGeneral();
  const { toggleCompletion } = useReportCompletion();
  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const mainFacadeComponents = [
    { label: 'sem revestimento', value: 'sem revestimento' },
    { label: 'cimentado', value: 'cimentado' },
    { label: 'pintura/ textura', value: 'pintura/ textura' },
    { label: 'grafiato', value: 'grafiato' },
    {
      label: 'cerâmica de baixa qualidade',
      value: 'cerâmica de baixa qualidade',
    },
    { label: 'ardósia ou compatível', value: 'ardósia ou compatível' },
    { label: 'COBOGO', value: 'COBOGO' },
    { label: 'vulcanizado ou fornicado', value: 'vulcanizado ou fornicado' },
    { label: 'granitina ou compatível', value: 'granitina ou compatível' },
    { label: 'cerâmica de boa qualidade', value: 'cerâmica de boa qualidade' },
    { label: 'porcelanato', value: 'porcelanato' },
    { label: 'madeira ou emadeirado', value: 'madeira ou emadeirado' },
    { label: 'granito ou compatível', value: 'granito ou compatível' },
    { label: 'mármore ou compatível', value: 'mármore ou compatível' },
    { label: 'alumínio composto', value: 'alumínio composto' },
    { label: 'pele de vidro', value: 'pele de vidro' },
    { label: 'pastilha', value: 'pastilha' },
    { label: 'outro', value: 'outro' },
    { label: 'cisterna/ poço artesiano', value: 'cisterna/ poço artesiano' },
    { label: 'depósito industrial', value: 'depósito industrial' },
  ];
  const infrastructureServicesAndEquipmentsComponents = [
    { label: 'piscina', value: 'piscina' },
    { label: 'sauna', value: 'sauna' },
    { label: 'playground', value: 'playground' },
    { label: 'interfone', value: 'interfone' },
    { label: 'pista de cooper', value: 'pista de cooper' },
    { label: 'jardins', value: 'jardins' },
    { label: 'portão eletrônico', value: 'portão eletrônico' },
    { label: 'gás canalizado', value: 'gás canalizado' },
    { label: 'poço artesiano', value: 'poço artesiano' },
    { label: 'quadra', value: 'quadra' },
    { label: 'tv a cabo', value: 'tv a cabo' },
    { label: 'pet place', value: 'pet place' },
    { label: 'salão de festas', value: 'salão de festas' },
    { label: 'churrasqueira', value: 'churrasqueira' },
    { label: 'portaria 24h', value: 'portaria 24h' },
    { label: 'CFTV', value: 'CFTV' },
    { label: 'sala de jogos', value: 'sala de jogos' },
    { label: 'coworking', value: 'coworking' },
    { label: 'gerador', value: 'gerador' },
    {
      label: 'hidrômetro individualizado',
      value: 'hidrômetro individualizado',
    },
    { label: 'academia', value: 'academia' },
    { label: 'lavanderia coletiva', value: 'lavanderia coletiva' },
    { label: 'tv satélite', value: 'tv satélite' },
    { label: 'loja de conveniência', value: 'loja de conveniência' },
    { label: 'brinquedoteca', value: 'brinquedoteca' },
    { label: 'salão gourmet', value: 'salão gourmet' },
    { label: 'vigilância eletrônica', value: 'vigilância eletrônica' },
  ];
  const fireProtection = [
    { label: 'não possui', value: 'não possui' },
    { label: 'alarme', value: 'alarme' },
    { label: 'hidrantes', value: 'hidrantes' },
    { label: 'extintor', value: 'extintor' },
    { label: 'escada de emergência', value: 'escada de emergência' },
    { label: 'iluminação de emergência', value: 'iluminação de emergência' },
  ];

  const handleSelectCheckboxValue = (
    event: ChangeEvent<HTMLInputElement>,
    setInputs: Dispatch<SetStateAction<CondominiumInformationProps>>,
    array_name: keyof CondominiumInformationProps
  ): void => {
    const { value, checked } = event.target;

    setInputs((prev) => ({
      ...prev,
      [array_name]: checked
        ? [...(prev[array_name] as string[]), value]
        : [...(prev[array_name] as string[]).filter((item) => item !== value)],
    }));
  };

  const handleInputChange = (
    field: keyof CondominiumInformationProps,
    value: string | number | boolean
  ): void => {
    setInputs((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSendJSON = useCallback(async () => {
    const condominiumInformationData =
      doesNotApply === YesOrNo.YES ? 'não se aplica' : inputs;
    try {
      const data: StaticalReportData = {
        condominium_information: condominiumInformationData,
      };
      const response = await patchStatisticalReport(osId, data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (!data) {
        throw new Error('Algo deu errado, tente novamente');
      }
      if (response.detail.status_code === StatusCode.OK) {
        toggleCompletion('condominium', true);
        getDataCallback();
      }
    } catch (error) {
      setErrorMessage(true);
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
    }
  }, [
    doesNotApply,
    inputs,
    osId,
    setErrorMessage,
    setOpenSnackbar,
    setSnackbarMessage,
    toggleCompletion,
  ]);

  function isLandInformationProps(
    obj: any
  ): obj is CondominiumInformationProps {
    return true;
  }

  useEffect(() => {
    if (
      propertyData?.manual_statistical_report?.condominium_information?.toString() ===
      'não se aplica'
    ) {
      setDoesNotApply(YesOrNo.YES);
      toggleCompletion('condominium', true);
    } else if (
      isLandInformationProps(
        propertyData?.manual_statistical_report?.condominium_information
      ) &&
      propertyData?.manual_statistical_report?.condominium_information !==
        undefined
    ) {
      setInputs(
        propertyData?.manual_statistical_report?.condominium_information
      );
      toggleCompletion('condominium', true);
    }
  }, [propertyData, toggleCompletion]);

  useEffect(() => {
    if (doesNotApply === YesOrNo.NO && inputs.fire_protection.length > 0) {
      setIsDisabled(false);
    }
  }, [doesNotApply, inputs]);

  useEffect(() => {
    if (doesNotApply !== YesOrNo.NO) {
      setInputs({
        type:
          propertyData?.real_estate_kind === PropertyType.APARTMENT
            ? 'apartamento'
            : 'casa',
        framing: '',
        wall_covering: '',
        number_of_floors: 0,
        number_of_units_per_floor: 0,
        appraisal_position: '',
        age: 0,
        number_of_blocks: 0,
        number_of_elevators: 0,
        use_type: '',
        number_of_units: 0,
        finishing_standard: '',
        preservation_state: '',
        location_status_level: '',
        area: 0,
        main_facade_components: [],
        fire_protection: [],
        other_fire_protections: '',
        infrastructure_services_and_equipments_components: [],
      });
      setCondominiumIsDisabled(true);
      setIsDisabled(false);
    } else {
      setCondominiumIsDisabled(false);
    }
  }, [doesNotApply, propertyData?.real_estate_kind]);

  return (
    <Grid container rowGap={2}>
      <Grid item container rowGap={2} columnGap={2}>
        <Grid item xs={2.5}>
          <SelectTextField
            id=""
            label="não se aplica"
            selectOptions={selectYesOrNo()}
            value={doesNotApply}
            setValue={setDoesNotApply}
          />
        </Grid>
        {propertyData?.real_estate_kind === PropertyType.HOUSE ? (
          <>
            <Tooltip
              arrow
              placement="top"
              title="número de unidades de condomínio"
            >
              <Grid item xs={2.5}>
                <StyledTextField
                  disabled={condominiumIsDisabled}
                  type="number"
                  label="número de unidades de condomínio"
                  value={inputs.number_of_units}
                  onChange={(e) =>
                    handleInputChange('number_of_units', e.target.value)
                  }
                />
              </Grid>
            </Tooltip>
            <Grid item xs={2.5}>
              <StyledTextField
                disabled={condominiumIsDisabled}
                type="number"
                label="área do condomínio"
                value={inputs.area}
                onChange={(e) => handleInputChange('area', e.target.value)}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={2.5}>
              <StyledSelect
                select
                disabled={condominiumIsDisabled}
                label="esquadrias"
                value={inputs.framing}
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                onChange={(e) => handleInputChange('framing', e.target.value)}
              >
                <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
                <StyledMenuItem value="alumínio">alumínio</StyledMenuItem>
                <StyledMenuItem value="ferro">ferro</StyledMenuItem>
                <StyledMenuItem value="madeira">madeira</StyledMenuItem>
                <StyledMenuItem value="PVC">PVC</StyledMenuItem>
                <StyledMenuItem value="vidro temperado">
                  vidro temperado
                </StyledMenuItem>
                <StyledMenuItem value="outros">outros</StyledMenuItem>
              </StyledSelect>
            </Grid>
            <Grid item xs={2.5}>
              <StyledSelect
                select
                disabled={condominiumIsDisabled}
                label="revestimento"
                value={inputs.wall_covering}
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                onChange={(e) =>
                  handleInputChange('wall_covering', e.target.value)
                }
              >
                <StyledMenuItem disabled value="">
                  selecione uma opção
                </StyledMenuItem>
                <StyledMenuItem value="pintura">pintura</StyledMenuItem>
              </StyledSelect>
            </Grid>
            <Grid item xs={2.5}>
              <StyledSelect
                select
                disabled={condominiumIsDisabled}
                label="localização"
                value={inputs.location_status_level}
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                onChange={(e) =>
                  handleInputChange('location_status_level', e.target.value)
                }
              >
                <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
                <StyledMenuItem value="boa">boa</StyledMenuItem>
                <StyledMenuItem value="regular">regular</StyledMenuItem>
                <StyledMenuItem value="ruim">ruim</StyledMenuItem>
                <StyledMenuItem value="ótima">ótima</StyledMenuItem>
              </StyledSelect>
            </Grid>
            <Grid item xs={2.5}>
              <StyledTextField
                disabled={condominiumIsDisabled}
                type="number"
                label="número de pavimentos"
                value={inputs.number_of_floors}
                onChange={(e) =>
                  handleInputChange('number_of_floors', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={2.5}>
              <StyledTextField
                disabled={condominiumIsDisabled}
                type="number"
                label="número de unidades por andar"
                value={inputs.number_of_units_per_floor}
                onChange={(e) =>
                  handleInputChange('number_of_units_per_floor', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={2.5}>
              <StyledSelect
                select
                disabled={condominiumIsDisabled}
                label="posição"
                value={inputs.appraisal_position}
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                onChange={(e) =>
                  handleInputChange('appraisal_position', e.target.value)
                }
              >
                <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
                <StyledMenuItem value="isolada/ frente do terreno">
                  isolada/ frente do terreno
                </StyledMenuItem>
                <StyledMenuItem value="isolada/ centro do terreno">
                  isolada/ centro do terreno
                </StyledMenuItem>
                <StyledMenuItem value="geminada em uma lateral">
                  geminada em uma lateral
                </StyledMenuItem>
                <StyledMenuItem value="geminada nas laterais">
                  geminada nas laterais
                </StyledMenuItem>
                <StyledMenuItem value="juntos aos fundos">
                  juntos aos fundos
                </StyledMenuItem>
                <StyledMenuItem value="junto a uma lateral">
                  junto a uma lateral
                </StyledMenuItem>
                <StyledMenuItem value="junto às laterais">
                  junto às laterais
                </StyledMenuItem>
                <StyledMenuItem value="ocupa todo o terreno">
                  ocupa todo o terreno
                </StyledMenuItem>
              </StyledSelect>
            </Grid>
            <Grid item xs={2.5}>
              <StyledTextField
                disabled={condominiumIsDisabled}
                type="number"
                label="idade estimada"
                value={inputs.age}
                onChange={(e) => handleInputChange('age', e.target.value)}
              />
            </Grid>
            <Grid item xs={2.5}>
              <StyledTextField
                disabled={condominiumIsDisabled}
                type="number"
                label="quantidade de blocos"
                value={inputs.number_of_blocks}
                onChange={(e) =>
                  handleInputChange('number_of_blocks', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={2.5}>
              <StyledTextField
                disabled={condominiumIsDisabled}
                type="number"
                label="número de elevadores"
                value={inputs.number_of_elevators}
                onChange={(e) =>
                  handleInputChange('number_of_elevators', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={2.5}>
              <StyledSelect
                select
                disabled={condominiumIsDisabled}
                label="uso"
                value={inputs.use_type}
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                onChange={(e) => handleInputChange('use_type', e.target.value)}
              >
                <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
                <StyledMenuItem value="comercial">comercial</StyledMenuItem>
                <StyledMenuItem value="residencial">residencial</StyledMenuItem>
                <StyledMenuItem value="misto">misto</StyledMenuItem>
              </StyledSelect>
            </Grid>
            <Grid item xs={2.5}>
              <StyledTextField
                disabled={condominiumIsDisabled}
                type="number"
                label="número de unidades"
                value={inputs.number_of_units}
                onChange={(e) =>
                  handleInputChange('number_of_units', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={2.5}>
              <StyledSelect
                select
                disabled={condominiumIsDisabled}
                label="padrão de acabamento"
                value={inputs.finishing_standard}
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                onChange={(e) =>
                  handleInputChange('finishing_standard', e.target.value)
                }
              >
                <StyledMenuItem disabled value="">
                  selecione uma opção
                </StyledMenuItem>
                <StyledMenuItem value="alto">alto</StyledMenuItem>
                <StyledMenuItem value="baixo">baixo</StyledMenuItem>
                <StyledMenuItem value="mínimo">mínimo</StyledMenuItem>
                <StyledMenuItem value="normal/ médio">
                  normal / médio
                </StyledMenuItem>
              </StyledSelect>
            </Grid>
          </>
        )}
        <Grid item xs={2.5} lg={2.5}>
          <StyledSelect
            select
            disabled={condominiumIsDisabled}
            label="estado de conservação"
            value={inputs.preservation_state}
            SelectProps={{
              IconComponent: ExpandMoreIcon,
            }}
            onChange={(e) =>
              handleInputChange('preservation_state', e.target.value)
            }
          >
            <StyledMenuItem>selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="bom">bom</StyledMenuItem>
            <StyledMenuItem value="regular">regular</StyledMenuItem>
            <StyledMenuItem value="ruim">ruim</StyledMenuItem>
            <StyledMenuItem value="em implantação">
              em implantação
            </StyledMenuItem>
          </StyledSelect>
        </Grid>
      </Grid>
      {propertyData?.real_estate_kind === PropertyType.HOUSE ? (
        <Grid container rowGap={1} columnGap={1}>
          <Grid item xs={12}>
            <Grid item xs={12} margin={2}>
              <FormLabel>proteção contra incêncio</FormLabel>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                disabled={condominiumIsDisabled}
                control={
                  <Checkbox
                    value="não possui"
                    checked={inputs.fire_protection.includes('não possui')}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleSelectCheckboxValue(e, setInputs, 'fire_protection')
                    }
                  />
                }
                label="não possui"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                disabled={condominiumIsDisabled}
                control={
                  <Checkbox
                    value="alarme"
                    checked={inputs.fire_protection.includes('alarme')}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleSelectCheckboxValue(e, setInputs, 'fire_protection')
                    }
                  />
                }
                label="alarme"
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid container item xs={12}>
            <Grid item xs={12} margin={2}>
              <FormLabel>fachada principal</FormLabel>
            </Grid>
            {mainFacadeComponents.map((item) => (
              <Grid item xs={2.5}>
                <FormGroup>
                  <FormControlLabel
                    disabled={condominiumIsDisabled}
                    control={
                      <Checkbox
                        value={item.value}
                        checked={inputs.main_facade_components.includes(
                          item.value
                        )}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleSelectCheckboxValue(
                            e,
                            setInputs,
                            'main_facade_components'
                          )
                        }
                      />
                    }
                    label={item.label}
                  />
                </FormGroup>
              </Grid>
            ))}
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} margin={2}>
              <FormLabel>proteção contra incêncio</FormLabel>
            </Grid>
            <Grid container item xs={12} rowGap={2} columnGap={2}>
              {fireProtection.map((item) => (
                <Grid item xs={2.5}>
                  <FormGroup>
                    <FormControlLabel
                      disabled={condominiumIsDisabled}
                      control={
                        <Checkbox
                          value={item.value}
                          checked={inputs.fire_protection.includes(item.value)}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleSelectCheckboxValue(
                              e,
                              setInputs,
                              'fire_protection'
                            )
                          }
                        />
                      }
                      label={item.label}
                    />
                  </FormGroup>
                </Grid>
              ))}
              <Grid item xs={3}>
                <StyledTextField
                  disabled={condominiumIsDisabled}
                  label="outros"
                  value={inputs.other_fire_protections}
                  onChange={(e) =>
                    handleInputChange('other_fire_protections', e.target.value)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      <Grid container item xs={12}>
        <Grid item xs={12} margin={2}>
          <FormLabel>infraestrutura / serviços / equipamentos</FormLabel>
        </Grid>
        {infrastructureServicesAndEquipmentsComponents.map((item) => (
          <Grid item xs={2.5}>
            <FormGroup>
              <FormControlLabel
                disabled={condominiumIsDisabled}
                control={
                  <Checkbox
                    value={item.value}
                    checked={inputs.infrastructure_services_and_equipments_components.includes(
                      item.value
                    )}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleSelectCheckboxValue(
                        e,
                        setInputs,
                        'infrastructure_services_and_equipments_components'
                      )
                    }
                  />
                }
                label={item.label}
              />
            </FormGroup>
          </Grid>
        ))}
      </Grid>
      <Grid container justifyContent="flex-end" columnGap={2}>
        <Grid item paddingRight={2}>
          <EditButton
            type="submit"
            disabled={isDisabled}
            onClick={() => handleSendJSON()}
          >
            {IconSaveMS}
          </EditButton>
        </Grid>
      </Grid>
      {openSnackbar && <Snackbar />}
    </Grid>
  );
}
