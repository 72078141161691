/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { StatusCode } from '../../../../api/enumerations';
import { patchStatisticalReport } from '../../../../api/workOrders';
import {
  StaticalReportData,
  WorkOrderData,
} from '../../../../api/workOrders/types';
import { IconSaveMS } from '../../../../constants/icons';
import { GlobalContext } from '../../../../context/global';
import { getErrorMessage } from '../../../../helpers';
import useGeneral from '../../../../hooks/useGeneral';
import { useReportCompletion } from '../../../../hooks/useReportCompletion';
import Snackbar from '../../../Snackbar';
import {
  EditButton,
  StyledMenuItem,
  StyledSelect,
  StyledTextField,
} from '../styles';

interface DiagnosticProps {
  market_uptake: string;
  effective_sell_deadline_in_months: number | null;
  target_audience: string;
  negatiation_facilitators: string;
  market_perfomance: string;
  number_of_offers_status_level: string;
  liquidity: string;
  observations: string;
  independent_unities_complementary_information: string;
}

interface DiagnosticDataProps {
  propertyData: WorkOrderData | undefined;
  getDataCallback: () => Promise<void>;
}

export function Diagnostic({
  propertyData,
  getDataCallback,
}: DiagnosticDataProps): JSX.Element {
  const [allFilled, setAllFilled] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [inputs, setInputs] = useState<DiagnosticProps>({
    market_uptake: '',
    effective_sell_deadline_in_months: null,
    target_audience: '',
    negatiation_facilitators: '',
    market_perfomance: '',
    number_of_offers_status_level: '',
    liquidity: '',
    observations: '',
    independent_unities_complementary_information: '',
  });

  const { osId } = useGeneral();
  const { toggleCompletion } = useReportCompletion();
  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  useEffect(() => {
    if (propertyData) {
      const updateData = {
        market_uptake:
          propertyData.manual_statistical_report?.market_diagnosis
            ?.market_uptake || '',
        effective_sell_deadline_in_months:
          propertyData.manual_statistical_report?.market_diagnosis
            ?.effective_sell_deadline_in_months || null,
        target_audience:
          propertyData.manual_statistical_report?.market_diagnosis
            ?.target_audience || '',
        negatiation_facilitators:
          propertyData.manual_statistical_report?.market_diagnosis
            ?.negatiation_facilitators || '',
        market_perfomance:
          propertyData.manual_statistical_report?.market_diagnosis
            ?.market_perfomance || '',
        number_of_offers_status_level:
          propertyData.manual_statistical_report?.market_diagnosis
            ?.number_of_offers_status_level || '',
        liquidity:
          propertyData.manual_statistical_report?.market_diagnosis?.liquidity ||
          '',
        observations:
          propertyData.manual_statistical_report?.market_diagnosis
            ?.observations || '',
        independent_unities_complementary_information:
          propertyData.manual_statistical_report?.market_diagnosis
            ?.independent_unities_complementary_information || '',
      };
      setInputs(updateData);
    }
  }, [propertyData, toggleCompletion]);

  const handleInputChange = (
    field: keyof DiagnosticProps,
    value: string | number
  ): void => {
    setInputs((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const checkIfAllFieldsAreFilled = (object: DiagnosticProps): boolean => {
    return Object.values(object).every((value) => value !== '');
  };

  const handleSendJSON = useCallback(async () => {
    try {
      const data: StaticalReportData = {
        market_diagnosis: inputs,
      };
      const response = await patchStatisticalReport(osId, data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (!data) {
        throw new Error('Algo deu errado, tente novamente');
      }
      if (response.detail.status_code === StatusCode.OK) {
        toggleCompletion('diagnostic', true);
        getDataCallback();
      }
    } catch (error) {
      setErrorMessage(true);
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
    }
  }, [
    inputs,
    osId,
    setErrorMessage,
    setOpenSnackbar,
    setSnackbarMessage,
    toggleCompletion,
  ]);

  useEffect(() => {
    setAllFilled(checkIfAllFieldsAreFilled(inputs));
    allFilled === true ? setIsDisabled(false) : setIsDisabled(true);
  }, [allFilled, inputs, toggleCompletion]);

  return (
    <Grid container rowGap={2} columnGap={2}>
      <Grid item container columnGap={2}>
        <Grid item xs={3}>
          <StyledSelect
            select
            label="absorção pelo mercado"
            value={inputs.market_uptake}
            SelectProps={{
              IconComponent: ExpandMoreIcon,
            }}
            onChange={(e) => handleInputChange('market_uptake', e.target.value)}
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="difícil">difícil</StyledMenuItem>
            <StyledMenuItem value="fácil">fácil</StyledMenuItem>
            <StyledMenuItem value="extremamente fácil">
              extremamente fácil
            </StyledMenuItem>
          </StyledSelect>
        </Grid>
        <Grid item xs={3}>
          <StyledTextField
            label="prazo de venda efetivo (meses)"
            type="number"
            value={inputs.effective_sell_deadline_in_months}
            onChange={(e) =>
              handleInputChange(
                'effective_sell_deadline_in_months',
                Number(e.target.value)
              )
            }
          />
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <StyledTextField
            label="público alvo"
            value={inputs.target_audience}
            onChange={(e) =>
              handleInputChange('target_audience', e.target.value)
            }
          />
        </Grid>
      </Grid>
      <Grid item container columnGap={2} rowGap={2}>
        <Grid item xs={3}>
          <StyledTextField
            label="facilitadores na negociação"
            value={inputs.negatiation_facilitators}
            onChange={(e) =>
              handleInputChange('negatiation_facilitators', e.target.value)
            }
          />
        </Grid>
        <Grid item xs={3}>
          <StyledSelect
            select
            label="desempenho do mercado"
            value={inputs.market_perfomance}
            SelectProps={{
              IconComponent: ExpandMoreIcon,
            }}
            onChange={(e) =>
              handleInputChange('market_perfomance', e.target.value)
            }
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="aparentemente esquecido">
              aparentemente esquecido
            </StyledMenuItem>
            <StyledMenuItem value="sem destaque">sem destaque</StyledMenuItem>
            <StyledMenuItem value="aparentemente baixo">
              aparentemente baixo
            </StyledMenuItem>
          </StyledSelect>
        </Grid>
        <Grid item xs={3}>
          <StyledSelect
            select
            label="número de ofertas"
            value={inputs.number_of_offers_status_level}
            SelectProps={{
              IconComponent: ExpandMoreIcon,
            }}
            onChange={(e) =>
              handleInputChange('number_of_offers_status_level', e.target.value)
            }
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="aparentemente esquecido">
              aparentemente esquecido
            </StyledMenuItem>
            <StyledMenuItem value="sem destaque">sem destaque</StyledMenuItem>
            <StyledMenuItem value="aparentemente baixo">
              aparentemente baixo
            </StyledMenuItem>
          </StyledSelect>
        </Grid>
        <Grid item xs={3}>
          <StyledSelect
            select
            label="liquidez do imóvel"
            value={inputs.liquidity}
            SelectProps={{
              IconComponent: ExpandMoreIcon,
            }}
            onChange={(e) => handleInputChange('liquidity', e.target.value)}
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="provavelmente alta">
              provavelmente alta
            </StyledMenuItem>
            <StyledMenuItem value="sem destaque">sem destaque</StyledMenuItem>
            <StyledMenuItem value="provavelmente baixa">
              provavelmente baixa
            </StyledMenuItem>
          </StyledSelect>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          label="observações importantes"
          value={inputs.observations}
          onChange={(e) => handleInputChange('observations', e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          label="informações complementares de unidades autônomas"
          value={inputs.independent_unities_complementary_information}
          onChange={(e) =>
            handleInputChange(
              'independent_unities_complementary_information',
              e.target.value
            )
          }
        />
      </Grid>
      <Grid container justifyContent="flex-end" columnGap={2}>
        <Grid item paddingRight={2}>
          <EditButton disabled={isDisabled} onClick={() => handleSendJSON()}>
            {IconSaveMS}
          </EditButton>
        </Grid>
      </Grid>
      {openSnackbar && <Snackbar />}
    </Grid>
  );
}
