/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { StatusCode } from '../../../../api/enumerations';
import { patchStatisticalReport } from '../../../../api/workOrders';
import {
  StaticalReportData,
  WorkOrderData,
} from '../../../../api/workOrders/types';
import { IconSaveMS } from '../../../../constants/icons';
import { GlobalContext } from '../../../../context/global';
import { getErrorMessage } from '../../../../helpers';
import useGeneral from '../../../../hooks/useGeneral';
import { useReportCompletion } from '../../../../hooks/useReportCompletion';
import Snackbar from '../../../Snackbar';
import {
  EditButton,
  StyledMenuItem,
  StyledSelect,
  StyledTextField,
} from '../styles';

interface EvaluationResultInputProps {
  objective: string;
  maximum_imediate_liquidation_value_or_minimum_term?: string;
  minimum_term_in_months: number;
  minimum_imediate_liquidation_value_or_maximum_term: string;
  maximum_term_in_months: number;
  total_market_value: number;
  interval_result_upper_bound: string;
  interval_result_lower_bound: string;
  reference_area_in_squared_meters: number;
  used_unitary_value: string;
  assigned_appraisal_value: number;
  use_mean_value: boolean;
  unuse_mean_value_reasoning: string;
}

interface EvaluationResultProps {
  propertyData: WorkOrderData | undefined;
  getDataCallback: () => Promise<void>;
}

export function EvaluationResult({
  propertyData,
  getDataCallback,
}: EvaluationResultProps): JSX.Element {
  const [allFilled, setAllFilled] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [inputs, setInputs] = useState<EvaluationResultInputProps>({
    objective: '',
    maximum_imediate_liquidation_value_or_minimum_term: '',
    minimum_term_in_months: 0,
    minimum_imediate_liquidation_value_or_maximum_term: '',
    maximum_term_in_months: 0,
    total_market_value: 0,
    interval_result_upper_bound: '',
    interval_result_lower_bound: '',
    reference_area_in_squared_meters: 0,
    used_unitary_value: '',
    assigned_appraisal_value: 0,
    use_mean_value: Boolean('true'),
    unuse_mean_value_reasoning: '',
  });

  const { osId } = useGeneral();
  const { toggleCompletion } = useReportCompletion();
  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const handleInputChange = (
    field: keyof EvaluationResultInputProps,
    value: string | number | boolean
  ): void => {
    setInputs((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const formatToReal = (value: string): string => {
    // Remove todos os caracteres que não são números
    const numericValue = value.replace(/\D/g, '');

    // Formata como moeda
    const formattedValue = (Number(numericValue) / 100).toLocaleString(
      'pt-BR',
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    );

    return formattedValue;
  };

  const checkIfAllFieldsAreFilled = (
    object: EvaluationResultInputProps
  ): boolean => {
    return Object.entries(object).every(
      ([key, value]) =>
        key === 'maximum_imediate_liquidation_value_or_minimum_term' ||
        value !== ''
    );
  };

  const handleSendJSON = useCallback(async () => {
    try {
      const data: StaticalReportData = {
        evaluation_result: inputs,
      };
      const response = await patchStatisticalReport(osId, data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (!data) {
        throw new Error('Algo deu errado, tente novamente');
      }
      if (response.detail.status_code === StatusCode.OK) {
        toggleCompletion('evaluationResult', true);
        getDataCallback();
      }
    } catch (error) {
      setErrorMessage(true);
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
    }
  }, [
    inputs,
    osId,
    setErrorMessage,
    setOpenSnackbar,
    setSnackbarMessage,
    toggleCompletion,
  ]);

  useEffect(() => {
    if (propertyData?.manual_statistical_report?.evaluation_result) {
      setInputs(propertyData?.manual_statistical_report.evaluation_result);
      if (allFilled) {
        toggleCompletion('evaluationResult', true);
      }
    }
  }, [allFilled, propertyData, toggleCompletion]);

  useEffect(() => {
    setAllFilled(checkIfAllFieldsAreFilled(inputs));
    if (allFilled) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [allFilled, inputs, toggleCompletion]);

  return (
    <Grid container rowGap={2} columnGap={2}>
      <Grid item container xs={12} columnGap={2}>
        <Grid item sx={{ flexGrow: 1 }}>
          <StyledSelect
            select
            label="objetivo da avaliação"
            value={inputs.objective}
            SelectProps={{
              IconComponent: ExpandMoreIcon,
            }}
            onChange={(e) => handleInputChange('objective', e.target.value)}
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="Valor de mercado">
              valor de mercado
            </StyledMenuItem>
            <StyledMenuItem value="Valor de mercado + Valor de liquidação imediata">
              valor de mercado + valor de liquidação imediata
            </StyledMenuItem>
          </StyledSelect>
        </Grid>
        {inputs.objective ===
          'Valor de mercado + Valor de liquidação imediata' && (
          <Grid item xs={3}>
            <StyledTextField
              label="VLI máximo / Prazo mínimo"
              value={inputs.maximum_imediate_liquidation_value_or_minimum_term}
              onChange={(e) =>
                handleInputChange(
                  'maximum_imediate_liquidation_value_or_minimum_term',
                  e.target.value
                )
              }
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          label="prazo mínimo (meses)"
          value={inputs.minimum_term_in_months}
          type="number"
          onChange={(e) =>
            handleInputChange('minimum_term_in_months', e.target.value)
          }
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          label="VLI mínimo / Prazo máximo"
          value={inputs.minimum_imediate_liquidation_value_or_maximum_term}
          onChange={(e) =>
            handleInputChange(
              'minimum_imediate_liquidation_value_or_maximum_term',
              e.target.value
            )
          }
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          label="prazo máximo (meses)"
          value={inputs.maximum_term_in_months}
          type="number"
          onChange={(e) =>
            handleInputChange('maximum_term_in_months', e.target.value)
          }
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          label="valor de mercado total do imóvel (R$)"
          value={inputs.total_market_value}
          type="number"
          onChange={(e) =>
            handleInputChange('total_market_value', e.target.value)
          }
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          label="resultado intervalar - limite superior (R$)"
          value={inputs.interval_result_upper_bound}
          onChange={(e) =>
            handleInputChange('interval_result_upper_bound', e.target.value)
          }
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          label="resultado intervalar - limite inferior (R$)"
          value={inputs.interval_result_lower_bound}
          onChange={(e) =>
            handleInputChange('interval_result_lower_bound', e.target.value)
          }
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          label="área de referência (m²)"
          value={inputs.reference_area_in_squared_meters}
          type="number"
          onChange={(e) =>
            handleInputChange(
              'reference_area_in_squared_meters',
              e.target.value
            )
          }
        />
      </Grid>
      <Grid item xs={2.8}>
        <StyledTextField
          label="valor unitário adotado (R$)"
          value={inputs.used_unitary_value}
          onChange={(e) =>
            handleInputChange(
              'used_unitary_value',
              formatToReal(e.target.value)
            )
          }
        />
      </Grid>
      <Grid item xs={2.8}>
        <StyledTextField
          label="valor atribuído ao imóvel"
          value={inputs.assigned_appraisal_value}
          type="number"
          onChange={(e) =>
            handleInputChange('assigned_appraisal_value', e.target.value)
          }
        />
      </Grid>
      <Grid item xs={2.5}>
        <StyledSelect
          select
          label="foi adotado o valor médio"
          value={String(inputs.use_mean_value)}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('use_mean_value', e.target.value)}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="true">sim</StyledMenuItem>
          <StyledMenuItem value="false">não</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={2.5}>
        <StyledTextField
          required
          label="justifique"
          value={inputs.unuse_mean_value_reasoning}
          onChange={(e) =>
            handleInputChange('unuse_mean_value_reasoning', e.target.value)
          }
        />
      </Grid>
      <Grid container justifyContent="flex-end" columnGap={2}>
        <Grid item paddingRight={2}>
          <EditButton disabled={isDisabled} onClick={() => handleSendJSON()}>
            {IconSaveMS}
          </EditButton>
        </Grid>
      </Grid>
      {openSnackbar && <Snackbar />}
    </Grid>
  );
}
